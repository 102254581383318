import { MapProvider } from "./Ridingazua.MapWrapper";
import { Resources } from "./Ridingazua.Resources";

export enum MapType {
    OPEN_STREET_MAP = 1,
    GOOGLE_ROADMAP = 2,
    GOOGLE_TERRAIN = 3,
    GOOGLE_SATELLITE = 4,
    GOOGLE_HYBRID = 5,
    KAKAO_ROADMAP = 6,
    KAKAO_SKYVIEW = 7,
    OVERLAY_KAKAO_ROADVIEW = 98,
    OVERLAY_IMAGE_MAP = 99,
}

export class MapTypeHelper {
    static get allTypes(): MapType[] {
        return [
            MapType.OPEN_STREET_MAP,
            MapType.GOOGLE_ROADMAP,
            MapType.GOOGLE_TERRAIN,
            MapType.GOOGLE_SATELLITE,
            MapType.GOOGLE_HYBRID,
            MapType.KAKAO_ROADMAP,
            MapType.KAKAO_SKYVIEW,
        ];
    }

    static get googleBasedTypes(): MapType[] {
        return [
            MapType.OPEN_STREET_MAP,
            MapType.GOOGLE_ROADMAP,
            MapType.GOOGLE_TERRAIN,
            MapType.GOOGLE_SATELLITE,
            MapType.GOOGLE_HYBRID,
        ];
    }
    static get kakaoBasedTypes(): MapType[] {
        return [
            MapType.KAKAO_ROADMAP,
            MapType.KAKAO_SKYVIEW
        ];
    }

    static mapTypeId(mapType: MapType): String {
        switch (mapType) {
            case MapType.OPEN_STREET_MAP:
                return 'OSM';
            case MapType.GOOGLE_ROADMAP:
                return 'roadmap';
            case MapType.GOOGLE_TERRAIN:
                return 'terrain';
            case MapType.GOOGLE_SATELLITE:
                return 'satellite';
            case MapType.GOOGLE_HYBRID:
                return 'hybrid';
            case MapType.KAKAO_ROADMAP:
                return 'kakao_roadmap';
            case MapType.KAKAO_SKYVIEW:
                return 'kakao_skyview';
            case MapType.OVERLAY_KAKAO_ROADVIEW:
                return 'overlay_kakao_roadview';
            case MapType.OVERLAY_IMAGE_MAP:
                return 'overlay_image_map';
        }
    }

    static kakaoMapTypeId(mapType: MapType): kakao.maps.MapTypeId | null {
        switch (mapType) {
            case MapType.KAKAO_ROADMAP:
                return kakao.maps.MapTypeId.ROADMAP;
            case MapType.KAKAO_SKYVIEW:
                return kakao.maps.MapTypeId.SKYVIEW;
            case MapType.OVERLAY_IMAGE_MAP:
                return kakao.maps.MapTypeId.OVERLAYIMAGEMAP;
        }
    }

    static mapTypeName(mapType: MapType): string {
        switch (mapType) {
            case MapType.OPEN_STREET_MAP:
                return Resources.text.map_type_osm;
            case MapType.GOOGLE_ROADMAP:
                return Resources.text.map_type_google_road;
            case MapType.GOOGLE_TERRAIN:
                return Resources.text.map_type_google_terrain;
            case MapType.GOOGLE_SATELLITE:
                return Resources.text.map_type_google_satellite;
            case MapType.GOOGLE_HYBRID:
                return Resources.text.map_type_google_hybrid;
            case MapType.KAKAO_ROADMAP:
                return Resources.text.map_type_kakao_road;
            case MapType.KAKAO_SKYVIEW:
                return Resources.text.map_type_kakao_skyview;
            case MapType.OVERLAY_IMAGE_MAP:
                return Resources.text.map_type_overlay_image_map;
            default:
                return Resources.text.map_type_osm;
        }
    }

    static mapTypeBy(value: number): MapType {
        switch (value) {
            case MapType.OPEN_STREET_MAP:
                return MapType.OPEN_STREET_MAP;
            case MapType.GOOGLE_ROADMAP:
                return MapType.GOOGLE_ROADMAP;
            case MapType.GOOGLE_TERRAIN:
                return MapType.GOOGLE_TERRAIN;
            case MapType.GOOGLE_SATELLITE:
                return MapType.GOOGLE_SATELLITE;
            case MapType.GOOGLE_HYBRID:
                return MapType.GOOGLE_HYBRID;
            case MapType.KAKAO_ROADMAP:
                return MapType.KAKAO_ROADMAP;
            case MapType.KAKAO_SKYVIEW:
                return MapType.KAKAO_SKYVIEW;
            case MapType.OVERLAY_IMAGE_MAP:
                return MapType.OVERLAY_IMAGE_MAP;
            default:
                return MapType.OPEN_STREET_MAP;
        }
    }

    static mapProvider(mapType: MapType): MapProvider {
        switch (mapType) {
            case MapType.OPEN_STREET_MAP:
            case MapType.GOOGLE_ROADMAP:
            case MapType.GOOGLE_TERRAIN:
            case MapType.GOOGLE_SATELLITE:
            case MapType.GOOGLE_HYBRID:
            case MapType.OVERLAY_IMAGE_MAP:
                return MapProvider.GOOGLE;
            case MapType.KAKAO_ROADMAP:
            case MapType.KAKAO_SKYVIEW:
                return MapProvider.KAKAKO;
            default:
                return MapProvider.GOOGLE;
        }
    }
}
