import { CheckboxController, HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { MapController } from './Ridingazua.MapController';
import { StorageController } from './Ridingazua.StorageController';
import { PlaceSearchResult, Point } from '../common/Ridingazua.Model';
import Axios from 'axios';
import { ApplicationEvent, ApplicationEventListener, ApplicationState } from './Ridingazua.ApplicationState';
import { isNothing, Utility } from '../common/Ridingazua.Utility';
import { LatLngBounds, MapWrapper, LatLng, MapInfoWindow } from './Ridingazua.MapWrapper';

export class PlaceSearchController implements ApplicationEventListener {
    private static instance: PlaceSearchController;

    private get map(): MapWrapper {
        return ApplicationState.map;
    }

    static get isShowing(): boolean {
        return this.instance.div.style.display !== 'none';
    }

    static set isShowing(value: boolean) {
        if (this.isShowing === value) {
            return;
        }
        this.instance.div.style.display = value ? 'block' : 'none';

        if (value) {
            this.instance.focusInput();
        } else {
            this.instance.blurInput();
            this.instance.clearAllSearchResults(true);
        }
    }

    static set currentBounds(value: LatLngBounds | null) {
        if (!this.instance) {
            return;
        }
        this.instance.currentBounds = value;
    }

    static createInstance(): PlaceSearchController {
        if (!this.instance) {
            this.instance = new PlaceSearchController();
        }
        return this.instance;
    }

    private div: HTMLDivElement;
    private divSearchTextResult: HTMLDivElement;

    private inputForGooglePlaces: HTMLInputElement;
    private inputForKakaoPlaces: HTMLInputElement;
    private googlePlacesSearchBox: google.maps.places.SearchBox;

    private checkboxCurrentBounds: CheckboxController;

    // 지도의 중심 좌표를 보고 판단하도록 하자
    // private checkboxKoreaOnly: CheckboxController;

    private _currentBounds?: LatLngBounds = null;
    private get currentBounds(): LatLngBounds | null {
        return this._currentBounds;
    }
    private set currentBounds(value: LatLngBounds) {
        this._currentBounds = value;
        if (this.checkboxCurrentBounds.isChecked) {
            this.googlePlacesSearchBox.setBounds(value.toGoogle());
        } else {
            this.googlePlacesSearchBox.setBounds(null);
        }

        this.updateInputSearchText();
    }

    private constructor() {
        let div = document.createElement('div');
        div.id = 'div-search-place';
        div.classList.add('children-vertical-spacing');
        div.style.textAlign = 'right';
        div.style.display = 'none'; // 처음에는 보여주지 않는다.
        this.div = div;
        HTMLUtility.appendInputHiddenAutofocus(div);
        document.getElementById('div-right-top').appendChild(div);

        let divSearchTextResult = document.createElement('div');
        divSearchTextResult.id = 'div-search-text-result';
        divSearchTextResult.classList.add('children-spacing');
        div.appendChild(divSearchTextResult);
        this.divSearchTextResult = divSearchTextResult;

        this.addInputForGooglePlaces();
        this.addInputForKakaoPlaces();
        this.addDivSearchResultControl();
        this.addCheckboxes();
        this.updateInputSearchText();
        ApplicationState.addListener(this);

        // google 지도는 초기화 되어있지만, 바로 bounds를 호출하면 undefined가 반환되는 관계로 setTimeout 처리
        setTimeout(() => {
            this.currentBounds = this.map.bounds;
        }, 100);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any | null): void {
        switch (event) {
            case ApplicationEvent.SELECTED_BASE_MAP_CHANGED:
                this.updatePlaceMarkers();
                break;
            default:
                break;
        }
    }

    private addInputForGooglePlaces() {
        let input = document.createElement('input');
        this.inputForGooglePlaces = input;
        input.type = 'text';
        input.classList.add('controls', 'search_text');
        input.placeholder = Resources.text.search_place_by_google;
        this.divSearchTextResult.appendChild(input);

        let searchBox = new google.maps.places.SearchBox(input);
        this.googlePlacesSearchBox = searchBox;

        searchBox.addListener('places_changed', () => {
            this.clearAllSearchResults(false);

            let places = searchBox.getPlaces();

            if (!places.length) {
                return;
            }

            if (places.length > 20) {
                places.splice(20);
            }

            this.placeSearchResults = places.map((place) => {
                return new PlaceSearchResult(
                    place.name,
                    LatLng.fromGoogle(place.geometry.location),
                    place.id
                );
            });

            this.setMapVisibleAllMarkers();
        });
    }

    private addInputForKakaoPlaces() {
        let input = document.createElement('input');
        this.inputForKakaoPlaces = input;
        input.type = 'text';
        input.classList.add('controls', 'search_text');
        input.placeholder = Resources.text.search_place_by_kakao;
        this.divSearchTextResult.appendChild(input);
        input.onkeydown = (event) => {
            switch (event.key.toLowerCase()) {
                case 'enter':
                case 'return':
                    this.searchKakaoPlaces(input.value);
                    return false;
                default:
                    break;
            }
        };
    }

    private addDivSearchResultControl() {
        let div = document.createElement('div');
        div.classList.add('box', 'children-spacing');
        this.divSearchTextResult.appendChild(div);

        let buttonClear = HTMLUtility.createIconButton(Resources.text.search_place_clear, 'clear', (button) => {
            this.clearAllSearchResults(true);
        });

        div.appendChild(buttonClear);

        // 추후에 적용하자.
        /*
        let buttonList = HTMLUtility.createIconButton(Resources.text.search_place_list, 'list', (button) => {
            // TODO
        });

        div.appendChild(buttonList);
        */
    }

    private addCheckboxes() {
        let divCheckboxes = document.createElement('div');
        divCheckboxes.classList.add('box', 'children-spacing');
        divCheckboxes.style.display = 'inline-block';
        divCheckboxes.style.marginTop = '2px';
        this.div.appendChild(divCheckboxes);

        let checkboxCurrentBounds = new CheckboxController();
        checkboxCurrentBounds.title = Resources.text.search_place_current_bounds;

        checkboxCurrentBounds.isChecked = StorageController.get('search_place_current_bounds_checked') == 'true';

        checkboxCurrentBounds.onchange = (checked) => {
            StorageController.set('search_place_current_bounds_checked', checked ? 'true' : 'false');
            this.googlePlacesSearchBox.setBounds(checked ? this.currentBounds.toGoogle() : null);
        };
        divCheckboxes.appendChild(checkboxCurrentBounds.div);
        this.checkboxCurrentBounds = checkboxCurrentBounds;

        // 지도의 중심좌표를 보고 판단하도록 하자
        /*
        let checkboxKoreaOnly = new CheckboxController();
        checkboxKoreaOnly.title = Resources.text.search_place_korea_only;
        checkboxKoreaOnly.isChecked = StorageController.get('search_place_korea_only_checked') == 'true';
        checkboxKoreaOnly.onchange = (_) => {
            this.resetInputSearchText();
        };
        divCheckboxes.appendChild(checkboxKoreaOnly.div);
        this.checkboxKoreaOnly = checkboxKoreaOnly;
        */
    }

    private lastKoreaOnly?: boolean = null;

    private get isKoreaOnly(): boolean {
        let center = this.map.center;
        return Utility.pointInPolygin(
            [
                center.latitude,
                center.longitude
            ],
            ApplicationState.boundsSouthKorea
        );
    }

    /**
     * 지도의 중심 좌표에 따라 search text input을 변경한다.
     * 중심좌표가 한국일 경우, inputForKakaoPlaces를 노출하고, 아닐 경우, inputForGooglePlaces를 노출한다.
     * @returns
     */
    private updateInputSearchText() {
        // let isKoreaOnly = this.checkboxKoreaOnly.isChecked;

        let isKoreaOnly = this.isKoreaOnly;
        if (this.lastKoreaOnly == isKoreaOnly) {
            return;
        }

        // StorageController.set('search_place_korea_only_checked', isKoreaOnly ? 'true' : 'false');
        this.lastKoreaOnly = isKoreaOnly;
        this.inputForGooglePlaces.value = '';
        this.inputForKakaoPlaces.value = '';
        if (isKoreaOnly) {
            this.inputForGooglePlaces.blur();
            $(this.inputForGooglePlaces).hide();
            $(this.inputForKakaoPlaces).show();
        } else {
            this.inputForKakaoPlaces.blur();
            $(this.inputForKakaoPlaces).hide();
            $(this.inputForGooglePlaces).show();
        }
    }

    private searching = false;

    private _placeSearchResults: PlaceSearchResult[] = [];

    private get placeSearchResults(): PlaceSearchResult[] {
        return this._placeSearchResults;
    }

    private set placeSearchResults(value: PlaceSearchResult[]) {
        this._placeSearchResults = value;
        this.updatePlaceMarkers();
        this.map.showInfoWindowFirstPlaceMarker();
    }

    private async searchKakaoPlaces(keyword: string) {
        if (this.searching) {
            return;
        }

        if (!keyword.trim().length) {
            return;
        }

        this.searching = true;

        this.clearAllSearchResults(false);

        let params = {
            query: keyword,
        };

        let isCurrentBounds = this.checkboxCurrentBounds.isChecked

        if (isCurrentBounds) {
            let bounds = this.map.bounds;
            params['rect'] = `${bounds.west},${bounds.north},${bounds.east},${bounds.south}`;
        }

        let url = 'https://dapi.kakao.com/v2/local/search/keyword.json';
        let kakaoRestApiKey = 'b98c404461ee3414677771f42e9dc4bf';
        try {
            let response = await Axios.get(url, {
                params: params,
                headers: {
                    Authorization: `KakaoAK ${kakaoRestApiKey}`,
                },
            });

            let items = response?.data?.documents;

            if (isNothing(items)) {
                toastr.error(Resources.text.failed_to_search_places);
                return;
            }

            if (!items.length) {
                toastr.error(Resources.text.no_place_search_results);
                return;
            }

            this.placeSearchResults = items.map((item) => {
                return new PlaceSearchResult(
                    item.place_name,
                    new LatLng(item.y, item.x),
                    item.id
                );
            });

            if (!isCurrentBounds) {
                this.setMapVisibleAllMarkers();
            }
        } catch (error) {
            toastr.error(Resources.text.failed_to_search_places);
            return;
        } finally {
            setTimeout(
                () => {
                    this.searching = false;
                },
                1000
            );
        }
    }

    private updatePlaceMarkers() {
        let i = 0;

        let markers = this.placeSearchResults.map((placeSearchResult) => {
            return this.map.createPlaceMarker(
                placeSearchResult.name,
                i++,
                placeSearchResult.position,
                placeSearchResult.placeId
            );
        });

        this.map.placeMarkers = markers;

        let placeId = MapInfoWindow.showingPlaceId;
        
        if (placeId) {
            this.map.showInfoWindowForPlaceId(placeId);
        }
    }

    private setMapVisibleAllMarkers() {
        MapController.setVisibleAllPoints(
            this.placeSearchResults.map((item) => {
                return Point.fromPosition(item.position);
            })
        );
    }

    private clearAllSearchResults(clearText: boolean) {
        if (clearText) {
            this.inputForGooglePlaces.value = '';
            this.inputForKakaoPlaces.value = '';
        }

        this.map.placeMarkers?.forEach((marker) => {
            marker.map = null;
        });

        this.map.placeMarkers = null;
        this.map.showingInfoWindow = null;
        this.placeSearchResults = [];
    }

    private focusInput() {
        let isKoreaOnly = this.isKoreaOnly;
        (isKoreaOnly ? this.inputForKakaoPlaces : this.inputForGooglePlaces).focus();
    }

    private blurInput() {
        this.inputForGooglePlaces.blur();
        this.inputForKakaoPlaces.blur();
    }
}
