import { Chart, ChartDataItem, ChartDataItemAccessory, ChartConfiguration, ChartRange, ChartData } from './Ridingazua.Chart';
import { ApplicationState, ApplicationEventListener, ApplicationEvent } from './Ridingazua.ApplicationState';
import { Point, WaypointType, CourseRange, Waypoint, CourseExtra } from '../common/Ridingazua.Model';
import { WaypointImagesForChart } from './Ridingazua.WaypointImagesForChart';
import { Resources } from './Ridingazua.Resources';
import { Task, TaskManager } from './Ridingazua.TaskManager';
import { ClimbListDialogController } from './Ridingazua.ClimbListDialogController';
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { APIManager } from './Ridingazua.APIManager';
import { ElevationChartImageDialogController } from './Ridingazua.ElevationChartImageDialogController';
import { ElevationSettingsDialogController } from './Ridingazua.ElevationSettingsDialogController';
import { StorageController } from './Ridingazua.StorageController';
import { MenuController, MenuItem } from './Ridingazua.MenuController';
import { WaypointDialogContorller } from './Ridingazua.WaypointDialogController';
import { SectionEditor, SplitTask } from './Ridingazua.SectionEditor';
import { ElevationLoader } from '../common/Ridingazua.ElevationLoader';
import { Statics } from '../common/Ridingazua.Statics';
import { SelectedRangeController } from './Ridingazua.SelectedRangeController';
import { WaypointListDialogController, WaypointListDialogType } from './Ridingazua.WaypointListDialogController';
import { isNothing } from '../common/Ridingazua.Utility';
import { LatLng } from './Ridingazua.MapWrapper';
import { MapConstants } from './Ridingazua.MapConstants';

export class ElevationChartController implements ApplicationEventListener {
    private static instance?: ElevationChartController;
    private chart: Chart;
    private div: HTMLDivElement;
    private divChart: HTMLDivElement;
    private buttonReload: HTMLButtonElement;
    private imgReloadElevationProgress: HTMLImageElement;
    private buttonShowAll: HTMLButtonElement;

    private get defaultHeight(): number {
        return window.innerHeight * 0.3;
    }
    private minHeight = 100;
    private _height: number;
    getHeight(): number {
        return this._height;
    }
    private get height(): number {
        return this._height;
    }
    private set height(value: number) {
        if (this._height == value) {
            return;
        }
        this._height = value;
        this.updateHeight();
    }

    static set selection(value: ChartRange) {
        if (!this.instance) {
            return;
        }

        this.instance.chart.selection = value;
    }

    static get selection(): ChartRange {
        return this.instance?.chart.selection;
    }

    private constructor() {
        this._height = this.defaultHeight;

        let div = document.createElement('div');
        div.appendChild(this.createDragHandleDiv());
        div.appendChild(this.creatToolsDiv());
        div.appendChild(this.createDivChart());
        this.div = div;

        this.createChart();
        ApplicationState.addListener(this);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        if (this.isShowing) {
            switch (event) {
                case ApplicationEvent.SET_CURSOR:
                    let point = arg as Point;
                    if (!point || isNothing(point.distanceFromCourseStart)) {
                        return;
                    }
                    ElevationChartController.showCursorOnChart(point.distanceFromCourseStart)
                    break;

                case ApplicationEvent.REMOVE_CURSOR:
                    ElevationChartController.removeCursor();
                    break;

                case ApplicationEvent.COURSE_LOADED:
                case ApplicationEvent.SECTION_CHANGED:
                case ApplicationEvent.SECTIONS_CHANGED:
                case ApplicationEvent.SELECTED_SECTION_CHANGED:
                case ApplicationEvent.COURSE_ELEVATIONS_SMOOTHED:
                case ApplicationEvent.COURSE_ELEVATIONS_RELOADED:
                    ApplicationState.course.updateTotalValues();
                    this.refresh();
                    break;
                case ApplicationEvent.WINDOW_RESIZED:
                    this.refresh();
                    break;
                case ApplicationEvent.DESELECT_RANGE:
                    ElevationChartController.cancelZoom();
                    ElevationChartController.selection = null;
                    break;
            }
        }

        switch (event) {
            case ApplicationEvent.SELECT_RANGE:
                this.selectRangeAndShow(arg as CourseRange);
                break;
        }
    }

    static getInstance(): ElevationChartController {
        if (!this.instance) {
            this.instance = new ElevationChartController();
        }
        return this.instance;
    }

    private dragStartHeight?: number;
    private dragStartY?: number;

    private createDragHandleDiv() {
        let dragHandleDiv = document.createElement('div');
        dragHandleDiv.classList.add('ns-resize');
        dragHandleDiv.style.height = '10px';
        dragHandleDiv.classList.add('center');

        let handleSpan = document.createElement('span');
        handleSpan.style.display = 'inline-block';
        handleSpan.style.height = '4px';
        handleSpan.style.width = '30%';
        handleSpan.style.borderRadius = '2px';
        handleSpan.style.backgroundColor = '#dddddd';
        dragHandleDiv.appendChild(handleSpan);

        this.setDragEventHandler(dragHandleDiv);

        return dragHandleDiv;
    }

    private setDragEventHandler(dragHandleDiv: HTMLDivElement) {
        // touchstart, mousedown은 dragHandleDiv에 연결하지만,
        // touchmove, mousemove, touchend, mouseup은 document에 연결한다.

        dragHandleDiv.ontouchstart = (event) => {
            this.onDragStart(event);
        };

        dragHandleDiv.onmousedown = (event) => {
            this.onDragStart(event);
        };

        document.ontouchmove = (event) => {
            this.onDragMove(event);
        };

        document.onmousemove = (event) => {
            this.onDragMove(event);
        };

        document.ontouchend = () => {
            this.onDragEnd();
        };

        document.onmouseup = () => {
            this.onDragEnd();
        };
    }

    private clientYFromEvent(event: MouseEvent | TouchEvent): number | undefined {
        if (event instanceof MouseEvent) {
            return event.clientY;
        } else if (event instanceof TouchEvent) {
            if (!event.touches.length) {
                return;
            }
            return event.touches[0].clientY;
        }
    }

    private onDragStart(event: MouseEvent | TouchEvent) {
        this.dragStartHeight = this.height;
        this.dragStartY = this.clientYFromEvent(event);
    }

    private onDragMove(event: MouseEvent | TouchEvent) {
        if (isNothing(this.dragStartY)) {
            return;
        }

        let clientY = this.clientYFromEvent(event);
        let dHeight = this.dragStartY - clientY;
        this.height = this.dragStartHeight + dHeight;
    }

    private onDragEnd() {
        if (isNothing(this.dragStartY)) {
            return;
        }

        this.dragStartHeight = null;
        this.dragStartY = null;

        if (this.height < this.minHeight) {
            this.close();
        }
    }

    private creatToolsDiv(): HTMLDivElement {
        let toolsDiv = document.createElement('div');
        toolsDiv.classList.add('children-spacing');
        toolsDiv.style.height = '30px';
        toolsDiv.style.lineHeight = '30px';
        toolsDiv.style.padding = '5px';
        toolsDiv.style.textAlign = 'right';

        let findClimbsButton = document.createElement('button');
        findClimbsButton.classList.add('small');
        findClimbsButton.textContent = Resources.text.find_climbs_in_course_short;
        findClimbsButton.onclick = () => {
            ClimbListDialogController.show();
        };
        toolsDiv.appendChild(findClimbsButton);

        let reloadButton = document.createElement('button');
        reloadButton.classList.add('small');
        reloadButton.textContent = Resources.text.elevation_reload;
        reloadButton.onclick = () => {
            this.updateReloadButton(true);
            let task = ReloadElevationTask.createInstance();

            if (!task) {
                return;
            }

            task.completion = () => {
                this.updateReloadButton(false);
            };

            TaskManager.doTask(task);
        };
        toolsDiv.appendChild(reloadButton);
        this.buttonReload = reloadButton;

        let smoothButton = document.createElement('button');
        smoothButton.classList.add('small');
        smoothButton.textContent = Resources.text.elevation_smooth;
        smoothButton.onclick = () => {
            TaskManager.doTask(new SmoothElevationTask());
        };
        toolsDiv.appendChild(smoothButton);

        let settingsButton = HTMLUtility.createIconButton(
            Resources.text.elevation_settings,
            'settings',
            () => {
                ElevationSettingsDialogController.show();
            }
        );
        settingsButton.classList.add('small');
        toolsDiv.appendChild(settingsButton);

        let waypointsButton = HTMLUtility.createIconButton(
            Resources.text.waypoints,
            'place',
            () => {
                WaypointListDialogController.show(WaypointListDialogType.SET_VISIBLE_IN_ELEVATION_CHART);
            }
        );
        waypointsButton.classList.add('small');
        toolsDiv.appendChild(waypointsButton);

        let imgReloadElevationProgress = document.createElement('img');
        imgReloadElevationProgress.classList.add('icon-small');
        imgReloadElevationProgress.style.verticalAlign = 'middle';
        imgReloadElevationProgress.style.marginBottom = '1px';
        imgReloadElevationProgress.style.marginRight = '2px';
        imgReloadElevationProgress.src = Statics.image('progress.gif');
        this.imgReloadElevationProgress = imgReloadElevationProgress;

        let imageButton = HTMLUtility.createIconButton(
            Resources.text.elevation_chart_save_as_image,
            'insert_photo',
            () => {
                ElevationChartImageDialogController.show(this.chart);
            }
        );
        imageButton.classList.add('small');
        toolsDiv.appendChild(imageButton);

        let closeButton = HTMLUtility.createIconButton(
            Resources.text.close,
            'close',
            () => {
                this.close();
            }
        );
        closeButton.classList.add('small');
        toolsDiv.appendChild(closeButton);

        toolsDiv.append(this.createDivLeftTools());

        return toolsDiv;
    }

    private createDivLeftTools(): HTMLDivElement {
        let div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.display = 'inline';
        div.style.left = '10px';

        let buttonShowAll = document.createElement('button');
        div.appendChild(buttonShowAll);
        buttonShowAll.classList.add('small');
        buttonShowAll.style.visibility = 'hidden';
        buttonShowAll.textContent = Resources.text.elevation_chart_show_all;
        buttonShowAll.onclick = () => {
            this.chart.isZoomed = false;
        }
        this.buttonShowAll = buttonShowAll;

        return div;
    }

    private createDivChart(): HTMLDivElement {
        let divChart = document.createElement('div');
        divChart.style.position = 'absolute';
        divChart.style.top = '50px';
        divChart.style.left = '0';
        divChart.style.right = '0';
        divChart.style.bottom = '0';
        this.divChart = divChart;
        return divChart;
    }

    private updateShowAllButton() {
        this.buttonShowAll.style.visibility = this.chart.isZoomed ? 'visible' : 'hidden';
    }

    static show() {
        this.getInstance().show();
    }

    private show() {
        let targetDiv = document.getElementById('div-chart');
        targetDiv.style.visibility = 'visible';
        if (!this.div.parentElement) {
            targetDiv.appendChild(this.div);
        }

        this.updateHeight();

        if (this.height < this.minHeight) {
            this.height = Math.max(this.minHeight, this.defaultHeight);
        }
    }

    static close() {
        this.instance?.close();
    }

    private close() {
        let targetDiv = document.getElementById('div-chart');
        targetDiv.style.visibility = 'hidden';
        this.updateHeight();
    }

    get isShowing(): boolean {
        let targetDiv = document.getElementById('div-chart');
        let isShowing = (targetDiv.style.visibility !== 'hidden');
        if (!this.div.parentElement) {
            isShowing = false;
        }
        return isShowing;
    }

    static toggle() {
        if (this.instance?.isShowing) {
            this.close();
        } else {
            this.show();
        }
    }

    private updateReloadButton(loading: boolean) {
        if (loading) {
            this.buttonReload.insertBefore(this.imgReloadElevationProgress, this.buttonReload.firstChild);
        } else {
            this.imgReloadElevationProgress.remove();
        }
    }

    private updateHeight() {
        let height = this.height;

        let targetDiv = document.getElementById('div-chart');
        if (targetDiv.style.visibility == 'hidden') {
            height = 0;
        }

        targetDiv.style.height = `${height}px`;
        this.refresh();

        ApplicationState.executeListeners(ApplicationEvent.UPDATE_BOTTOM_ELEMENTS_LAYOUT)
    }

    private valueXForContextMenu?: number;

    private createContextMenuController(): MenuController {
        let menuItems: MenuItem[] = [
            {
                id: 'addWaypoint',
                name: Resources.text.add_waypoint,
                forEditing: true,
                action: () => {
                    let instance = ElevationChartController.instance;
                    if (isNothing(instance.valueXForContextMenu)) {
                        return;
                    }

                    WaypointDialogContorller.showUsingDistance(instance.valueXForContextMenu);
                }
            },
            {
                id: 'split',
                name: Resources.text.split_section,
                forEditing: true,
                action: () => {
                    let instance = ElevationChartController.instance;
                    let course = ApplicationState.course;
                    let virtualPoint = course.getVirtualPointByDistance(instance.valueXForContextMenu);
                    let section = course.sections[virtualPoint.sectionIndex];
                    TaskManager.doTask(
                        new SplitTask(section, virtualPoint.latitude, virtualPoint.longitude)
                    );
                }
            }
        ]

        menuItems = menuItems.filter((menuItem) => {
            if (SectionEditor.isLocked) {
                return menuItem.forEditing != true;
            } else {
                return true;
            }
        });

        let menuController = new MenuController(menuItems);

        menuController.div.onmousemove = () => {
            let instance = ElevationChartController.instance;
            let virtualPoint = ApplicationState.course.getVirtualPointByDistance(instance.valueXForContextMenu);
            instance.chart.drawCursorOfDataX(instance.valueXForContextMenu);
            ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, virtualPoint);
        };

        return menuController;
    }

    private zoomAndMoveMap(x: number) {
        let distance = x;
        let virtualPoint = ApplicationState.course.getVirtualPointByDistance(distance);
        if (!virtualPoint) {
            return;
        }
        ApplicationState.map.jump(
            new LatLng(virtualPoint.latitude, virtualPoint.longitude),
            ApplicationState.map.defaultZoomToPoint
        );

        ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, virtualPoint)
    }

    private createChart() {
        this.chart = ElevationChartController.createChart(this.divChart);
        this.chart.originalData = new ChartData(this.dataItemsForChart());
        this.chart.configuration.placeholderText = Resources.text.no_data_for_elevation_chart;
        this.chart.configuration.paddingWidthForZoom = 20;
        this.chart.configuration.onMouseMoveOverData = (x, y) => {
            let distance = x;
            let virtualPoint = ApplicationState.course.getVirtualPointByDistance(distance);
            if (!virtualPoint) {
                return;
            }
            ApplicationState.executeListeners(ApplicationEvent.SET_CURSOR, virtualPoint);
        };
        this.chart.configuration.onMouseDown = () => {
            MenuController.dismissAll();
        };
        this.chart.configuration.onLeftClick = (x) => {
            let instance = ElevationChartController.instance;
            instance.zoomAndMoveMap(x);
        };
        this.chart.configuration.onRightClick = (x, y, event) => {
            let virtualPoint = ApplicationState.course.getVirtualPointByDistance(x);
            if (!virtualPoint) {
                return;
            }

            let instance = ElevationChartController.instance;
            instance.zoomAndMoveMap(x);

            let pixelX = instance.chart.getPixelXFromValueX(x) / window.devicePixelRatio;
            let pixelY = instance.chart.getPixelYFromValueY(y) / window.devicePixelRatio;
            let chartCanvasRect = instance.chart.chartCanvas.getBoundingClientRect();
            pixelX += chartCanvasRect.left;
            pixelY += chartCanvasRect.top;

            setTimeout(() => {
                instance.valueXForContextMenu = x;
                instance.createContextMenuController().showIn(
                    document.body,
                    pixelX,
                    pixelY,
                    3
                );
            }, 100);
        };
        this.chart.configuration.onMouseOutOfChart = () => {
            ApplicationState.executeListeners(ApplicationEvent.REMOVE_CURSOR);
        };
        this.chart.configuration.onZoomChanged = () => {
            this.updateShowAllButton();

            let zoomRange = this.chart.zoomRange;

            if (zoomRange) {
                let course = ApplicationState.course;
                SelectedRangeController.selectedRange = CourseRange.createWithStartFinish(
                    course,
                    course.getVirtualPointByDistance(zoomRange.minXValue),
                    course.getVirtualPointByDistance(zoomRange.maxXValue)
                );
            }
        };
    }

    static createChart(div: HTMLDivElement, additionalConfiguration?: ChartConfiguration): Chart {
        let configuration: ChartConfiguration = {
            minWidth: 800,

            xAxisValueFormatter: (value) => {
                let fix = 0;
                if (chart.xAxisProperties.gap < 1000) {
                    fix = 1;
                }

                return (value / 1000.0).toFixed(fix);
            },

            yAxisValueFormatter: (value) => {
                return value.toFixed(0);
            },

            xValueFormatter: (value) => {
                return `${(value / 1000.0).toFixed(1)}km`;
            },

            yValueFormatter: (value) => {
                return `${value.toFixed(1)}m`;
            },

            cursorTextFormatter: (x, _) => {
                let course = ApplicationState.course;
                let elevationAndSlope = course.getElevationAndSlopeOfPosition(x);
                let elevation = elevationAndSlope[0];
                let slope = elevationAndSlope[1];
                let slopeString = `${(slope * 100).toFixed(1)}%`;
                let components = [
                    `${Resources.text.position}: ${chart.configuration.xValueFormatter(x)}`,
                    `${Resources.text.elevation}: ${chart.configuration.yValueFormatter(elevation)}`,
                    `${Resources.text.slope}: ${slopeString}`,
                ];
                return components.join('\n');
            }
        }

        if (additionalConfiguration) {
            for (let [key, value] of Object.entries(additionalConfiguration)) {
                (configuration as any)[key] = value;
            }
        }

        let chart = new Chart(div, configuration);

        return chart;
    }

    private refresh() {
        if (!this.chart) {
            return;
        }

        this.chart.originalData = new ChartData(this.dataItemsForChart());
        ElevationChartController.updateChartSizeConfiguration(this.chart, window.innerWidth, this.height);
        this.chart.redrawChart();
    }

    static refresh() {
        this.instance?.refresh();
    }

    static calculateGapOfXAxis(chart: Chart, width: number, min: number, max: number): number {
        let dynamic = max - min;
        let numberOfXAxisLabels = (width / 100);
        let gap = Math.floor(dynamic / numberOfXAxisLabels / 10000) * 10000; // 10km로 떨어지게 한다.

        if (gap == 0) {
            gap = Math.floor(dynamic / numberOfXAxisLabels / 5000) * 5000; // 5km로 떨어지게 한다.
        }

        if (gap == 0) {
            gap = Math.floor(dynamic / numberOfXAxisLabels / 1000) * 1000; // 1km로 떨어지게 한다.
        }

        if (gap == 0) {
            gap = Math.floor(dynamic / numberOfXAxisLabels / 500) * 500; // 0.5km로 떨어지게 한다.
        }

        if (gap == 0) {
            gap = Math.floor(dynamic / numberOfXAxisLabels / 100) * 100; // 0.1km로 떨어지게 한다.
        }

        if (gap == 0) {
            gap = dynamic;
        }

        return gap
    }

    static updateChartSizeConfiguration(chart: Chart, width: number, height: number) {
        chart.configuration.minYValue = Math.min(0, chart.originalData.minY);
        chart.configuration.maxYValue = chart.originalData.maxY;
        chart.configuration.yAxisValueGap = (() => {
            let dynamic = chart.configuration.maxYValue - chart.configuration.minYValue;
            let numberOfYAxisLabels = (height / 100);
            let gap = Math.floor(dynamic / numberOfYAxisLabels / 100) * 100; // 100으로 떨어지게 한다.

            if (gap == 0) {
                gap = Math.floor(dynamic / numberOfYAxisLabels / 50) * 50; // 50으로 떨어지게 한다.
            }

            if (gap == 0) {
                gap = Math.floor(dynamic / numberOfYAxisLabels / 10) * 10; // 10으로 떨어지게 한다.
            }

            return gap;
        })();

        chart.configuration.minXValue = 0;
        chart.configuration.maxXValue = chart.originalData.maxX;
        chart.configuration.xAxisValueGap = (() => {
            return this.calculateGapOfXAxis(chart, width, chart.configuration.minXValue, chart.configuration.maxXValue);
        })();

        chart.configuration.xValuesForLabel = (): number[] => {
            if (!chart.isZoomed) {
                return null;
            }

            let gap = this.calculateGapOfXAxis(chart, width, chart.xAxisProperties.minValue, chart.xAxisProperties.maxValue);
            let minValue = chart.xAxisProperties.minValue;
            let maxValue = chart.xAxisProperties.maxValue;

            // 소수점 표기 관련 수정
            let fix = (gap < 1000) ? 100 : 1000;
            // minValue = ;
            // maxValue = Math.ceil(maxValue / fix) * fix;

            let result: number[] = [];
            let c = Math.floor(minValue / fix) * fix;

            while (true) {
                if (c < minValue) {
                    // do nothing
                } else if (c >= maxValue) {
                    break;
                } else {
                    result.push(c);
                    c += gap;
                }

                c += gap;
            }
            result.push(maxValue);

            return result;
        };
    }

    private dataItemsForChart(): ChartDataItem[] {
        ApplicationState.course.updateTotalValues();
        let points = ApplicationState.course.allPoints();
        if (points.length == 1) {
            // 시작점만 찍은 상태는 데이터가 없는 것과 같다.
            return [];
        }

        return points.map((point, index) => {
            let chartDataItem = new ChartDataItem(point.distanceFromCourseStart, point.elevation);

            let waypoint = point.waypoint;
            if (waypoint) {
                let isVisibleInElevationChart = waypoint.isVisibleInElevationChart;
                if (isNothing(isVisibleInElevationChart)) {
                    isVisibleInElevationChart = Waypoint.defaultVisibleInElevationChart(waypoint.type);
                }

                if (isVisibleInElevationChart) {
                    let labelComponents: string[] = [];
                    let waypointName = waypoint.name?.trim();
                    if (waypointName && waypointName.length) {
                        labelComponents.push(waypointName);
                    }
                    labelComponents.push(point.elevation.toFixed(0));
                    let accessoryLabel = labelComponents.join(', ');
                    if (!accessoryLabel || !accessoryLabel.length) {
                        accessoryLabel = waypoint.type.name;
                    }

                    chartDataItem.accessory = new ChartDataItemAccessory(
                        WaypointImagesForChart.pinImageElementForType(waypoint.type),
                        accessoryLabel
                    );
                }
            }

            let isStartPoint = (index === 0);
            let isFinishPoint = (index === (points.length - 1));

            if (!chartDataItem.accessory) {
                if (isStartPoint) {
                    let isWaypointVisible = ApplicationState.course.extra?.isVisibleStartInElevationChart;
                    if (isNothing(isWaypointVisible)) {
                        isWaypointVisible = CourseExtra.defaultVisibleStartInElevationChart;
                    }

                    if (isWaypointVisible) {
                        let accessoryLabel = `${Resources.text.course_start}, ${point.elevation.toFixed(0)}`;
                        chartDataItem.accessory =
                            new ChartDataItemAccessory(WaypointImagesForChart.pinImageElementForType(WaypointType.Start), accessoryLabel);
                        chartDataItem.accessory.priority = -1;
                    }
                }

                if (isFinishPoint) {
                    let isWaypointVisible = ApplicationState.course.extra?.isVisibleFinishInElevationChart;
                    if (isNothing(isWaypointVisible)) {
                        isWaypointVisible = CourseExtra.defaultVisibleFinishInElevationChart;
                    }

                    if (isWaypointVisible) {
                        let accessoryLabel = `${Resources.text.course_finish}, ${point.elevation.toFixed(0)}`;
                        chartDataItem.accessory =
                            new ChartDataItemAccessory(WaypointImagesForChart.pinImageElementForType(WaypointType.Finish), accessoryLabel);
                        chartDataItem.accessory.priority = -1;
                    }
                }
            }

            return chartDataItem;
        });
    }

    private static showCursorOnChart(distance: number) {
        if (!this.instance?.isShowing) {
            return;
        }

        this.instance?.chart.drawCursorOfDataX(distance);
    }

    private static removeCursor() {
        if (!this.instance?.isShowing) {
            return;
        }

        this.instance?.chart.clearCursor();
    }

    private selectRangeAndShow(range: CourseRange) {
        ElevationChartController.show();
        ElevationChartController.selection = {
            minXValue: range.startPoint?.distanceFromCourseStart,
            maxXValue: range.finishPoint?.distanceFromCourseStart
        }
    }

    static setZoom(minXValue: number, maxXValue: number) {
        let instance = this.getInstance();
        instance.chart.zoomRange = {
            minXValue: minXValue,
            maxXValue: maxXValue
        }
        if (!instance.isShowing) {
            this.show();
        }
    }

    static cancelZoom() {
        this.instance.chart.zoomRange = null;
    }
}

export class SmoothElevationTask implements Task {
    static defaultElevationSmoothLevel = 15;
    static _elevationSmoothLevel =
        parseInt(StorageController.get('elevationSmoothLevel')) ||
        SmoothElevationTask.defaultElevationSmoothLevel;

    static get elevationSmoothLevel(): number {
        return this._elevationSmoothLevel;
    }

    static set elevationSmoothLevel(value: number) {
        this._elevationSmoothLevel = value;
        StorageController.set('elevationSmoothLevel', `${value}`);
    }

    private elevationsToRestore: number[];
    private smoothLevel = SmoothElevationTask.elevationSmoothLevel;

    do(): void {
        let course = ApplicationState.course;
        let allPoints = course.allPoints();
        this.elevationsToRestore = allPoints.map((point) => {
            return point.elevation;
        });
        let smoothed = ApplicationState.course.smoothElevations(this.smoothLevel);
        if (smoothed) {
            ApplicationState.executeListeners(ApplicationEvent.COURSE_ELEVATIONS_SMOOTHED, ApplicationState.course);
        } else {
            toastr.error(Resources.text.elevation_smooth_failed_by_no_original_elevations);
        }
    }

    undo(): void {
        let course = ApplicationState.course;
        let allPoints = course.allPoints();
        if (!this.elevationsToRestore || !allPoints || this.elevationsToRestore.length != allPoints.length) {
            return;
        }

        this.elevationsToRestore.forEach((elevation, index) => {
            allPoints[index].elevation = elevation;
        });
    }
}

class ReloadElevationTask implements Task {
    private static isLoading = false;
    private restoredElevations: number[];
    private restoredOriginalElevations: number[];
    private transactionId?: number;
    completion?: () => void;

    private constructor() { }

    static createInstance(): ReloadElevationTask | null {
        if (ReloadElevationTask.isLoading) {
            return null;
        }

        return new ReloadElevationTask();
    }

    async do(): Promise<void> {
        let transactionId = APIManager.newTransactionId();
        this.transactionId = transactionId;

        let course = ApplicationState.course;
        course.updateTotalValues();
        let points = course.allPoints();
        this.restoredElevations = points.map((point) => {
            return point.elevation || 0;
        });
        this.restoredOriginalElevations = points.map((point) => {
            return !isNothing(point.originalElevation) ? point.originalElevation : null;
        });
        ReloadElevationTask.isLoading = true;

        try {
            await ElevationLoader.loadElevations(points);
        } catch (error) {
            toastr.error(Resources.text.failed_to_reload_elevation);
        }

        if (this.transactionId === transactionId) {
            course.smoothElevations(SmoothElevationTask.elevationSmoothLevel);
            ApplicationState.executeListeners(ApplicationEvent.COURSE_ELEVATIONS_RELOADED, ApplicationState.course);
            this.transactionId = null;
            if (this.completion) {
                this.completion();
            }
        }

        ReloadElevationTask.isLoading = false;
    }

    undo(): void {
        if (ReloadElevationTask.isLoading) {
            // 로딩 중 취소
            ReloadElevationTask.isLoading = false;
            this.transactionId = null;
            if (this.completion) {
                this.completion();
            }
        } else {
            let points = ApplicationState.course.allPoints();
            for (let i = 0; i < points.length; i++) {
                points[i].elevation = this.restoredElevations[i];
                points[i].originalElevation = this.restoredOriginalElevations[i];
            }
            ApplicationState.executeListeners(ApplicationEvent.COURSE_ELEVATIONS_RELOADED, ApplicationState.course);
        }
    }
}
