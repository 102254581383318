import { HTMLUtility } from "./Ridingazua.HTMLUtility";
import { ApplicationState } from "./Ridingazua.ApplicationState";
import { SectionListDialogController } from "./Ridingazua.SectionListDialogController";
import { WaypointListDialogController, WaypointListDialogType } from "./Ridingazua.WaypointListDialogController";
import { ElevationChartController } from "./Ridingazua.ElevationChartController";
import { Resources } from "./Ridingazua.Resources";
import { LocationManager } from "./Ridingazua.LocationManager";

export class BottomToolsController {
    private static instance: BottomToolsController;

    static createInstance(): BottomToolsController {
        if (!this.instance) {
            this.instance = new BottomToolsController();
        }
        return this.instance;
    }

    private constructor() {
        let div = document.createElement('div');
        div.classList.add('box');
        div.classList.add('children-spacing');

        let buttons = [
            HTMLUtility.createIconButton(Resources.text.zoom_in_map, 'zoom_in', () => {
                let map = ApplicationState.map;
                map.zoom = map.zoom + 1;
            }),
            HTMLUtility.createIconButton(Resources.text.zoom_out_map, 'zoom_out', () => {
                let map = ApplicationState.map;
                map.zoom = map.zoom - 1;
            }),
            HTMLUtility.createIconButton(Resources.text.my_location, 'my_location', () => {
                LocationManager.watch();
            }),
            HTMLUtility.createIconButton(Resources.text.elevation_chart, 'trending_up', () => {
                ElevationChartController.toggle();
            }),
            HTMLUtility.createIconButton(Resources.text.sections, 'list', () => {
                SectionListDialogController.toggle();
            }),
            HTMLUtility.createIconButton(Resources.text.waypoints, 'place', () => {
                WaypointListDialogController.toggle(WaypointListDialogType.NORMAL);
            })
        ];

        buttons.forEach(button => {
            div.appendChild(button);
        });

        document.getElementById('div-right-bottom').appendChild(div);
    }
}