import { LatLng } from "./Ridingazua.MapWrapper";

export class KakaoMapWalker {
    private walker: kakao.maps.CustomOverlay;
    private content: HTMLElement;

    set map(map: kakao.maps.Map | null) {
        this.walker.setMap(map);
    }

    set position(position: LatLng) {
        this.walker.setPosition(
            position.toKakao()
        );
    }

    set angle(angle: number) {
        var threshold = 22.5;
        for (var i = 0; i < 16; i++) {
            if (angle > (threshold * i) && angle < (threshold * (i + 1))) {
                var className = 'm' + i;
                this.content.className = this.content.className.split(' ')[0];
                this.content.className += (' ' + className);
                break;
            }
        }
    }

    constructor(map: kakao.maps.Map, position: LatLng) {
        const content = document.createElement('div');
        const figure = document.createElement('div');
        const angleBack = document.createElement('div');

        content.className = 'MapWalker';
        figure.className = 'figure';
        angleBack.className = 'angleBack';

        content.appendChild(angleBack);
        content.appendChild(figure);

        var walker = new kakao.maps.CustomOverlay({
            map: map,
            content: content,
            position: position.toKakao(),
            yAnchor: 1
        });

        this.walker = walker;
        this.content = content;
    }
}