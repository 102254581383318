import { Point, Section, InstructionType } from '../common/Ridingazua.Model';
import { TaskManager, Task } from './Ridingazua.TaskManager';
import { Director } from './Ridingazua.Director';
import { Resources } from './Ridingazua.Resources';
import { SmoothElevationTask } from './Ridingazua.ElevationChartController';
import { APIManager } from './Ridingazua.APIManager';
import { LatLng, MapMarker, MapPolyline } from './Ridingazua.MapWrapper';
import { DirectionController } from './Ridingazua.DirectionController';
import { ApplicationEvent, ApplicationEventListener, ApplicationState } from './Ridingazua.ApplicationState';

export class SectionEditor implements ApplicationEventListener {
    private static editors: { [key: number]: SectionEditor } = {};

    private static _isLocked: boolean = false;

    static get isLocked(): boolean {
        return this._isLocked;
    }

    static set isLocked(value: boolean) {
        this._isLocked = value;
        ApplicationState.executeListeners(ApplicationEvent.EDITOR_LOCKED_CHANGED);
    }

    private static sortedEditors(): SectionEditor[] {
        return ApplicationState.sections.map((section) => {
            return this.editors[section.clientId];
        });
    }

    static get selectedEditor(): SectionEditor {
        let selectedEditors = this.sortedEditors().filter((editor) => {
            return editor.isSelected;
        });
        return selectedEditors[0];
    }

    static resetEditors() {
        this.editors = {};
        let course = ApplicationState.course;
        course.sections.forEach((section) => {
            if (!section.clientId) {
                section.clientId = Section.newClientId();
            }
            SectionEditor.editors[section.clientId] = new SectionEditor(section);
        });
    }

    static addNewEditor(section: Section) {
        this.editors[section.clientId] = new SectionEditor(section);
    }

    static removeEditor(clientId: number) {
        delete this.editors[clientId];
    }

    readonly section: Section;

    set isSelected(value: boolean) {
        ApplicationState.selectedSection = this.section;
    }

    get isSelected(): boolean {
        return ApplicationState.selectedSection == this.section;
    }

    private startPointMarker: MapMarker;
    private endPointMarker: MapMarker;
    private lines: MapPolyline[] = [];
    private waypointMarkers: MapMarker[] = [];
    private instructionMarkers: MapMarker[] = [];

    constructor(section: Section) {
        this.section = section;
        ApplicationState.addListener(this);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.COURSE_LOADED:
                if (!ApplicationState.sections.includes(this.section)) {
                    this.destroy();
                }

                break;

            case ApplicationEvent.SECTION_CHANGED:
                if (arg != this.section) {
                    return;
                }

                this.updateLines();
                break;

            case ApplicationEvent.SECTION_REMOVED:
                let removedSection = arg as Section;
                if (removedSection !== this.section) {
                    return;
                }
                this.destroy();
                break;

            case ApplicationEvent.SECTIONS_CHANGED:
            case ApplicationEvent.SELECTED_SECTION_CHANGED:
            case ApplicationEvent.SELECTED_BASE_MAP_CHANGED:
            case ApplicationEvent.UPDATE_LINES_REQUESTED:
                this.updateLines();
                break;
        }
    }

    get lastPoint(): Point | null {
        let points = this.section.points;
        if (points.length == 0) {
            return null;
        }

        return points[points.length - 1];
    }

    didMapClick(position: LatLng) {
        let lastPoint = this.lastPoint;
        if (lastPoint) {
            let endPoint = new Point(position.latitude, position.longitude);
            endPoint.isForLoadDirection = true;

            TaskManager.doTask(
                new DirectionTask(
                    this,
                    this.lastPoint,
                    endPoint
                )
            );
        } else {
            TaskManager.doTask(
                new SetStartPointTask(
                    this,
                    new Point(position.latitude, position.longitude)
                )
            );
        }
    }

    private setStartPoint() {
        this.removeStartPoint();

        if (!this.section.points.length) {
            return;
        }

        this.startPointMarker = ApplicationState.map.createStartOrEndPointMarker(
            true,
            LatLng.fromPoint(this.section.points[0])
        );
    }

    private removeStartPoint() {
        if (!this.startPointMarker) {
            return;
        }

        this.startPointMarker.map = null;
        this.startPointMarker = null;
    }

    private setEndPoint() {
        this.removeEndPoint();

        if (this.section.points.length < 2) {
            return;
        }

        this.endPointMarker = ApplicationState.map.createStartOrEndPointMarker(
            false,
            LatLng.fromPoint(this.lastPoint)
        );
    }

    private removeEndPoint() {
        if (!this.endPointMarker) {
            return;
        }

        this.endPointMarker.map = null;
        this.endPointMarker = null;
    }

    private removeLines() {
        if (!this.lines.length) {
            return;
        }

        this.lines.forEach(line => {
            line.map = null;
        });

        this.lines.splice(0, this.lines.length);
    }

    private removeWaypointMarkers() {
        this.waypointMarkers.forEach((marker) => {
            marker.map = null;
        });
        this.waypointMarkers.splice(0);
    }

    private removeInstructionMarkers() {
        this.instructionMarkers.forEach((marker) => {
            marker.map = null;
        });
        this.instructionMarkers.splice(0);
    }

    private updateLines() {
        this.removeStartPoint();
        this.removeEndPoint();
        this.removeLines();
        this.removeWaypointMarkers();
        this.removeInstructionMarkers();

        if (this.section.points.length == 0) {
            return;
        }

        if (this.isSelected) {
            this.setStartPoint();
            this.setEndPoint();
        }

        this.lines = this.createLines();
        this.addWaypointMarkers();
    }

    private createLines(): MapPolyline[] {
        var lines: MapPolyline[] = [];
        var buffer: Point[] = []

        let createLine = () => {
            if (!buffer.length) {
                return;
            }

            let lastPoint = buffer[buffer.length - 1];
            if (buffer.length > 1) {
                if (lastPoint.isForLoadDirection) {
                    lines.push(
                        this.createLoadingLine(
                            buffer.map((point) => { return LatLng.fromPoint(point) })
                        )
                    );
                } else {
                    let polylines = this.createDirectionLines(
                        buffer.map((point) => { return LatLng.fromPoint(point) })
                    );

                    polylines.forEach(polyline => {
                        lines.push(polyline);
                    });
                }
            }
        };

        this.section.points.forEach(point => {
            if (!buffer.length) {
                buffer.push(point);
            } else {
                let lastPoint = buffer[buffer.length - 1];
                if (lastPoint.isForLoadDirection == point.isForLoadDirection) {
                    buffer.push(point);
                } else {
                    createLine();
                    buffer = [lastPoint, point];
                }
            }
        });

        createLine();

        lines.forEach(line => {
            line.addListeners(
                ApplicationState.map,
                this.section,
                this.isSelected
            );
        });

        return lines;
    }

    private createDirectionLines(path: LatLng[]): MapPolyline[] {
        return ApplicationState.map.createDirectionPolylines(path, this.isSelected);
    }

    private createLoadingLine(path: LatLng[]): MapPolyline {
        return ApplicationState.map.createLoadingPolyline(path, this.isSelected);
    }

    private addWaypointMarkers() {
        let pointsHasWaypoint = this.section.points.filter(point => {
            return point.waypoint != null;
        });

        pointsHasWaypoint.forEach(point => {
            let marker = ApplicationState.map.createWaypointMarker(point);
            this.waypointMarkers.push(marker);
        });
    }

    private destroy() {
        ApplicationState.removeListener(this);
        this.removeStartPoint();
        this.removeEndPoint();
        this.removeLines();
        this.removeWaypointMarkers();
    }
}

class SetStartPointTask implements Task {
    private editor: SectionEditor;
    private point: Point;

    constructor(editor: SectionEditor, point: Point) {
        this.editor = editor;
        this.point = point;
    }

    do(): void {
        this.editor.section.points.push(this.point);
        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            this.editor.section
        );
    }

    undo(): void {
        this.editor.section.points.pop();
        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            this.editor.section
        );
    }
}

export class DirectionTask implements Task {
    private editor: SectionEditor;
    private point1: Point;
    private point2: Point;
    private indexOfPoint1: number;
    private indexOfPoint2: number;
    private director: Director;
    private transactionId?: number;

    private loadedPoints: Array<Point> = [];

    constructor(editor: SectionEditor, point1: Point, point2: Point) {
        this.editor = editor;
        this.point1 = point1;
        this.point2 = point2;
        this.director = DirectionController.selectedDirector;
    }

    async do() {
        let transactionId = APIManager.newTransactionId();
        this.transactionId = transactionId;

        // point1은 이미 section에 포함되어있을 것이다.
        // point2를 찍고 시작한다.
        let section = this.editor.section;
        this.indexOfPoint1 = section.points.indexOf(this.point1);

        section.points.splice(this.indexOfPoint1 + 1, 0, this.point2);
        this.indexOfPoint2 = section.points.indexOf(this.point2);

        if (this.loadedPoints.length == 0) {
            ApplicationState.executeListeners(
                ApplicationEvent.SECTION_CHANGED,
                section
            );

            try {
                await this.loadDirection(transactionId);
                this.resolve();
            } catch (error) {
                toastr.error(Resources.text.failed_to_route);
            }
        } else {
            // redo에 의해 실행될 경우에는 loadedPoints에 점들이 들어있을 수 있다.
            this.addLoadedPoints(this.loadedPoints);
            this.resolve();
        }
    }

    private resolve() {
        ApplicationState.course.smoothElevations(SmoothElevationTask.elevationSmoothLevel);
        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            this.editor.section
        );
    }

    undo() {
        let section = this.editor.section;
        if (this.loadedPoints.length > 0) {
            // 로드가 정상적으로 완료된 이후에 undo
            let indexOfFirstLoadedPoint = section.points.indexOf(this.loadedPoints[0]);
            if (indexOfFirstLoadedPoint < 0) {
                return;
            }

            section.points.splice(indexOfFirstLoadedPoint, this.loadedPoints.length);
            section.points.splice(indexOfFirstLoadedPoint, 0, this.point1);
        } else {
            // 로드 중, 또는 실패한 이후에 undo
            this.transactionId = null;
            let indexOfPoint2 = section.points.indexOf(this.point2);
            if (indexOfPoint2 < 0) {
                return;
            }
            section.points.splice(this.indexOfPoint2, 1);
        }

        ApplicationState.course.smoothElevations(SmoothElevationTask.elevationSmoothLevel);

        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_CHANGED,
            this.editor.section
        );
    }

    private async loadDirection(transactionId: number): Promise<void> {
        try {
            let points = await this.director.loadDirection(this.point1, this.point2);
            if (transactionId === this.transactionId) {
                this.addLoadedPoints(points);
            } else {
                // 파기된 상태
                // do nothing
            }
        } catch (error) {
            TaskManager.undoTask();
            throw error;
        }
    }

    private addLoadedPoints(points: Point[]) {
        // 로드를 요청한 시작점과 끝점은 실제 사용자가 찍은 좌표와 다를 수 있다.
        // 따라서 startPoint와, endPoint는 제거한 후에 그 사이에 points를 추가해야한다.
        let section = this.editor.section;
        this.indexOfPoint1 = section.points.indexOf(this.point1);
        this.indexOfPoint2 = section.points.indexOf(this.point2);

        if (this.indexOfPoint1 < 0 || this.indexOfPoint2 < 0) {
            return;
        }

        if (this.indexOfPoint1 > this.indexOfPoint2) {
            return;
        }

        section.points.splice(this.indexOfPoint1, this.indexOfPoint2 - this.indexOfPoint1 + 1);
        var cursor = this.indexOfPoint1;
        points.forEach((point) => {
            section.points.splice(cursor, 0, point);
            cursor++;
        });
        this.loadedPoints = points;
    }
}

export class SplitTask implements Task {
    private section: Section;
    private latitude: number;
    private longitude: number;
    private createdSection: Section;
    private newSectionName: string;

    constructor(section: Section, latitude: number, longitude: number) {
        this.section = section;
        this.latitude = latitude;
        this.longitude = longitude;
        this.newSectionName = ApplicationState.newSectionName(ApplicationState.course);
    }

    do() {
        let virtualPointInfo = this.section.virtualPointInfoByCoord(
            new LatLng(this.latitude, this.longitude)
        );

        let point = virtualPointInfo.point;
        let indexToInsert = virtualPointInfo.indexToInsert;

        // 현재 section에서 after 이후의 구간을 분할하여, 새 section에 사용할 points를 생성
        let newPointsOfSection = this.section.points.splice(indexToInsert);
        newPointsOfSection.unshift(point.clone());
        let newSection = Section.create(this.newSectionName);
        newSection.points = newPointsOfSection;

        // 새 section 삽입
        let sections = ApplicationState.sections;
        let indexOfCurrentSection = sections.indexOf(this.section);
        ApplicationState.insertSection(newSection, indexOfCurrentSection + 1);
        ApplicationState.selectedSection = newSection;

        // 현재 section의 마지막 점으로 point 삽입
        this.section.points.push(point.clone());

        ApplicationState.executeListeners(
            ApplicationEvent.SECTIONS_CHANGED,
            ApplicationState.sections
        );

        this.createdSection = newSection;
    }

    undo() {
        let points = this.createdSection.points;
        points.shift();

        this.section.points.pop();
        points.forEach((point) => {
            this.section.points.push(point);
        });

        ApplicationState.removeSection(this.createdSection);
        ApplicationState.selectedSection = this.section;

        ApplicationState.executeListeners(
            ApplicationEvent.SECTIONS_CHANGED,
            ApplicationState.sections
        );

        ApplicationState.executeListeners(
            ApplicationEvent.SECTION_REMOVED,
            this.createdSection
        );
    }
}