export class Colors {
    static coursePolylineStroke = '#061148';
    static coursePolylineFill = '#F357AB';
    static courseSelectedRangePolylineFill = '#FFFFFF'

    static get loadingPolyline() {
        return this.coursePolylineStroke;
    }

    static get myLocationCircleStroke() {
        return this.coursePolylineStroke;
    }
    
    static myLocationCircleFill = '#4285F4';

    static selectedBoundsRectangleStroke = '#FFA500';
}