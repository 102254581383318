import { Colors } from "../common/Ridingazua.Colors";
import { Bounds, Point, Section } from "../common/Ridingazua.Model";
import { Statics } from "../common/Ridingazua.Statics";
import { MapConstants } from "./Ridingazua.MapConstants";
import { MapController } from "./Ridingazua.MapController";
import { MapType, MapTypeHelper } from "./Ridingazua.MapType";
import { MapMarker, LatLng, LatLngBounds, MapProvider, MapWrapper, MapPolyline, MapRectangle } from "./Ridingazua.MapWrapper";
import { OverlayImageMapSettingsDialogController } from "./Ridingazua.OverlayImageMapSettingsDialogController";
import { Resources } from "./Ridingazua.Resources";

/**
 * 구글 지도를 제공하기 위한 wrapper
 */
export class GoogleMapWrapper extends MapWrapper {
    private _map: google.maps.Map;

    constructor() {
        super();

        let divMap = document.getElementById('div-map');

        let centerZoom = this.loadMapCenterZoomFromStorage();
        let latitude = centerZoom?.latitude || 37.512926;
        let longitude = centerZoom?.longitude || 127.002085;
        let zoom = centerZoom?.zoom || 14;

        this._map = new google.maps.Map(
            divMap,
            {
                draggableCursor: 'crosshair',
                mapTypeId: MapTypeHelper.mapTypeId(MapController.selectedMapType) as string,
                center: new google.maps.LatLng(latitude, longitude),
                zoom: zoom,
                disableDefaultUI: true,
                streetViewControl: true,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
            }
        );

        this.addOsmTypeToMap();
        this.addOverlayImageMap();
        this.addListeners();
    }

    private addOsmTypeToMap() {
        this._map.mapTypes.set(
            MapTypeHelper.mapTypeId(MapType.OPEN_STREET_MAP) as string,
            new google.maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                    // "Wrap" x (longitude) at 180th meridian properly
                    // NB: Don't touch coord.x: because coord param is by reference, and changing its x property breaks something in Google's lib
                    var tilesPerGlobe = 1 << zoom;
                    var x = coord.x % tilesPerGlobe;
                    if (x < 0) {
                        x = tilesPerGlobe + x;
                    }
                    // Wrap y (latitude) in a like manner if you want to enable vertical infinite scrolling

                    return `https://tile.openstreetmap.org/${zoom}/${coord.x}/${coord.y}.png`;
                },
                tileSize: new google.maps.Size(256, 256),
                name: 'OpenStreetMap',
                maxZoom: 18,
            })
        );
    }

    private addListeners() {
        this._map.addListener('click', (event) => {
            this.onMapClick(
                LatLng.fromGoogle(event.latLng)
            );
        });

        this._map.addListener('rightclick', (event) => {
            this.onMapRightClick(
                LatLng.fromGoogle(event.latLng)
            );
        });

        this._map.addListener('center_changed', () => {
            this.onCenterChanged();
        });

        this._map.addListener('idle', () => {
            this.onIdle();
        });

        this._map.addListener('zoom_changed', () => {
            this.onZoomChanged();
        });

        this._map.addListener('dragstart', () => {
            this.onDragStart();
        });

        this._map.addListener('mousemove', (event) => {
            // course 라인이 근접할 경우 cursor를 보여주는 처리
            /*
            let eventLatLng = event.latLng;
            let mousePoint = MapController.convertPointToPixelPosition(eventLatLng.lat(), eventLatLng.lng());
            console.log(mousePoint);

            let points = ApplicationState.course.allPoints();
            if (points.length < 1) {
                return;
            }

            let nearestDistance: number;
            let nearestPoints: Point[];
            for (let i = 1; i < points.length; i++) {
                let p1 = points[i - 1];
                let p2 = points[i];
                let point1 = MapController.convertPointToPixelPosition(p1.latitude, p1.longitude);
                let point2 = MapController.convertPointToPixelPosition(p2.latitude, p2.longitude);
                let distance = Utility.pointLineDistance(mousePoint[0], mousePoint[1], point1[0], point1[1], point2[0], point2[1]);
                if (distance > 20) {
                    continue;
                }

                if (isNothing(nearestDistance) || distance < nearestDistance) {
                    nearestDistance = distance;
                    nearestPoints = [p1, p2];
                }
            }

            if (nearestPoints) {
                let p1 = nearestPoints[0];
                let p2 = nearestPoints[1];
                let distanceFromP1 = Utility.distanceMeterBetween(p1.latitude, p1.longitude, eventLatLng.lat(), eventLatLng.lng());
                let distanceFromP2 = Utility.distanceMeterBetween(p2.latitude, p2.longitude, eventLatLng.lat(), eventLatLng.lng());
                let ratio = distanceFromP1 / (distanceFromP1 + distanceFromP2);
                let distance = ((p2.distanceFromCourseStart - p1.distanceFromCourseStart) * ratio) + p1.distanceFromCourseStart;
                let virtualPoint = ApplicationState.course.getVirtualPointByDistance(distance);
                MapController.setCursorMarker(virtualPoint);
                ElevationChartController.showCursorOnChart(distance);
            } else {
                MapController.removeCursorMarker();
                ElevationChartController.clearCursor();
            }
            */
        });
    }

    get mapProvider(): MapProvider {
        return MapProvider.GOOGLE
    }

    get availableMapTypes(): MapType[] {
        return [
            MapType.OPEN_STREET_MAP,
            MapType.GOOGLE_ROADMAP,
            MapType.GOOGLE_TERRAIN,
            MapType.GOOGLE_SATELLITE,
            MapType.GOOGLE_HYBRID
        ];
    }

    get map(): google.maps.Map {
        return this._map;
    }

    get div(): Element {
        return this._map.getDiv();
    }

    set mapType(mapType: MapType) {
        this._map.setMapTypeId(
            MapTypeHelper.mapTypeId(mapType) as string
        );
    }

    get center(): LatLng {
        return LatLng.fromGoogle(
            this._map.getCenter()
        );
    }

    set center(latLng: LatLng) {
        this._map.setCenter(
            latLng.toGoogle()
        );
    }

    get defaultZoomToPoint(): number {
        return 15;
    }

    get zoom(): number {
        return this._map.getZoom();
    }

    set zoom(zoom: number) {
        this._map.setZoom(zoom);
    }

    get bounds(): LatLngBounds {
        return LatLngBounds.fromGoogle(
            this._map.getBounds()
        );
    }

    set bounds(bounds: LatLngBounds) {
        this._map.fitBounds(
            bounds.toGoogle()
        )
    }

    pan(position: LatLng) {
        this._map.panTo(
            position.toGoogle()
        );
    }

    jump(position: LatLng, zoom: number) {
        this.zoom = zoom;
        this.pan(position);
    }

    setMyLocationMarker(position?: LatLng) {
        this.removeMyLocationMarker();

        if (!position) {
            return;
        }

        let marker = new google.maps.Marker({
            map: this._map,
            title: Resources.text.my_location,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: Colors.myLocationCircleFill,
                fillOpacity: 1.0,
                strokeColor: Colors.myLocationCircleStroke,
                strokeWeight: 2,
                scale: 5,
            },
            position: position.toGoogle(),
            zIndex: MapConstants.zIndexForMyLocationMarker,
        });

        marker.addListener('click', () => {
            this.removeMyLocationMarker();
        });

        this.myLocationMarker = new MapMarker(marker);
    }

    setCursorMarker(position?: LatLng) {
        if (this.cursorMarker) {
            if (position == null) {
                this.cursorMarker.map = null;
            } else {
                this.cursorMarker.map = this;
                this.cursorMarker.position = position;;
            }
            return;
        }

        if (!position) {
            return;
        }

        let icon = {
            url: Statics.image('start_marker.png'),
            scaledSize: new google.maps.Size(10, 10),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 5),
        };

        let cursorMarker = new google.maps.Marker({
            map: this._map,
            position: position.toGoogle(),
            icon: icon,
            draggable: false,
            clickable: true,
            zIndex: MapConstants.zIndexForCursorMarker,
        });

        cursorMarker.addListener('click', (event) => {
            this.onCursorMarkerClick();
        });

        cursorMarker.addListener('rightclick', (event) => {
            this.onCursorMarkerRightClick();
        });

        this.cursorMarker = new MapMarker(cursorMarker);
        this.cursorMarker.cursor = 'crosshair';
    }

    createStartOrEndPointMarker(isStart: boolean, latLng: LatLng): MapMarker {
        let iconUrl = isStart ? Statics.image('start_marker.png') : Statics.image('end_marker.png');

        let icon = {
            url: iconUrl,
            scaledSize: new google.maps.Size(10, 10),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(5, 5),
        };

        return new MapMarker(
            new google.maps.Marker({
                map: this._map,
                position: latLng.toGoogle(),
                icon: icon,
                zIndex: MapConstants.zIndexForStartFinishMarker,
            })
        );
    }

    createWaypointMarker(point: Point): MapMarker {
        let waypoint = point.waypoint;
        let iconImageName = waypoint.type.tag
            .replace(/\s+/g, '_')
            .toLowerCase();

        const marker = new google.maps.Marker({
            position: LatLng.fromPoint(point).toGoogle(),
            title: waypoint.name,
            icon: {
                url: Statics.image(`waypoint_icon/pin/${iconImageName}.png`),
                scaledSize: new google.maps.Size(32, 32),
            },
            zIndex: MapConstants.zIndexForWaypointMarker,
            map: this._map,
            draggable: false,
            clickable: true
        });

        marker.addListener('click', (event) => {
            this.onWaypointClick(point);
        });

        return new MapMarker(marker);
    }

    createSelectedRangePolylines(path: LatLng[], section: Section): MapPolyline[] {
        let backgroundPolyline = new google.maps.Polyline({
            geodesic: true,
            strokeColor: Colors.coursePolylineStroke,
            strokeOpacity: 1.0,
            strokeWeight: 5,
            zIndex: MapConstants.zIndexForSelectedRange,
            map: this._map,
            path: path.map((position) => { return position.toGoogle() }),
            draggable: false,
            editable: false,
        });

        let actualPolyline = new google.maps.Polyline({
            geodesic: true,
            strokeColor: Colors.courseSelectedRangePolylineFill,
            strokeOpacity: 1.0,
            strokeWeight: 1,
            zIndex: MapConstants.zIndexForSelectedRange,
            map: this._map,
            path: path.map((position) => { return position.toGoogle() }),
            draggable: false,
            editable: false,
        });

        let polylines = [backgroundPolyline, actualPolyline];

        polylines.forEach(polyline => {
            polyline.addListener('mousemove', (event) => {
                let latLng = event.latLng as google.maps.LatLng;
                this.onPolylineMousemove(
                    section,
                    LatLng.fromGoogle(latLng)
                );
            });

            polyline.addListener('mouseout', () => {
                this.onPolylineMouseout();
            });

            polyline.addListener('click', (event) => {
                let latLng = event.latLng as google.maps.LatLng;
                this.onPolylineClick(
                    LatLng.fromGoogle(latLng)
                )
            });

            polyline.addListener('rightclick', (event) => {
                let latLng = event.latLng as google.maps.LatLng;
                this.onPolylineRightClick(
                    LatLng.fromGoogle(latLng)
                )
            });
        });

        return [
            new MapPolyline(backgroundPolyline),
            new MapPolyline(actualPolyline)
        ];
    }

    createLoadingPolyline(path: LatLng[], isSelected: boolean): MapPolyline {
        return new MapPolyline(
            new google.maps.Polyline({
                geodesic: true,
                draggable: false,
                editable: false,
                strokeOpacity: 0,
                map: this._map,
                icons: [
                    {
                        icon: {
                            path: 'M 0,-1 0,1',
                            strokeColor: Colors.loadingPolyline,
                            strokeOpacity: isSelected ? 1 : 0.5,
                            strokeWeight: 2,
                            scale: 2,
                        },
                        offset: '0',
                        repeat: '10px',
                    },
                ],
                path: path.map((position) => { return position.toGoogle() })
            })
        );
    }

    createDirectionPolylines(path: LatLng[], isSelected: boolean): MapPolyline[] {
        let backgroundPolyline = new MapPolyline(
            new google.maps.Polyline({
                geodesic: true,
                strokeColor: Colors.coursePolylineStroke,
                strokeOpacity: isSelected ? 1 : 0.5,
                strokeWeight: 5,
                map: this._map,
                path: path.map((position) => { return position.toGoogle() }),
                zIndex: (
                    isSelected
                        ? MapConstants.zIndexForSelectedSection
                        : MapConstants.zIndexForDeselectedSection
                ),
                draggable: false,
                editable: false,
                clickable: true,
            })
        );

        let actualPolyline = new MapPolyline(
            new google.maps.Polyline({
                geodesic: true,
                strokeColor: Colors.coursePolylineFill,
                strokeOpacity: isSelected ? 1 : 0.5,
                strokeWeight: 1,
                map: this._map,
                path: path.map((position) => { return position.toGoogle() }),
                zIndex: (
                    isSelected
                        ? MapConstants.zIndexForSelectedSection
                        : MapConstants.zIndexForDeselectedSection
                ),
                draggable: false,
                editable: false,
                clickable: true,
            })
        );

        return [backgroundPolyline, actualPolyline];
    }

    createSegmentPolylines(path: LatLng[], isSelected: boolean): MapPolyline[] {
        let backgroundPolyline = new MapPolyline(
            new google.maps.Polyline({
                geodesic: true,
                strokeColor: Colors.coursePolylineStroke,
                strokeOpacity: (isSelected ? 1.0 : 0.5),
                strokeWeight: 5,
                zIndex: (
                    isSelected
                        ? MapConstants.zIndexForSelectedMapSection
                        : MapConstants.zIndexForMapSection
                ),
                map: this._map,
                path: path.map(position => position.toGoogle()),
                draggable: false,
                editable: false,
            })
        );

        let actualPolyline = new MapPolyline(
            new google.maps.Polyline({
                geodesic: true,
                strokeColor: Colors.courseSelectedRangePolylineFill,
                strokeOpacity: (isSelected ? 1.0 : 0.5),
                strokeWeight: 1,
                zIndex: (
                    isSelected
                        ? MapConstants.zIndexForSelectedMapSection
                        : MapConstants.zIndexForMapSection
                ),
                map: this._map,
                path: path.map(position => position.toGoogle()),
                draggable: false,
                editable: false,
            })
        )

        return [backgroundPolyline, actualPolyline];
    }

    createPlaceMarker(name: string, index: number, position: LatLng, placeId?: string): MapMarker {
        let googleMarker = new google.maps.Marker({
            map: this._map,
            title: name,
            label: { color: '#ffffff', text: `${index + 1}` },
            opacity: 1,
            position: position.toGoogle(),
            zIndex: (MapConstants.zIndexForPlaceMarker + 100 - index), // index가 낮을수록 더 높은 zIndex를 가진다.
        });

        let mapMarker = new MapMarker(googleMarker, placeId);

        googleMarker.addListener('click', () => {
            let zoom = Math.max(this.zoom, this.defaultZoomToPoint);
            this.onPlaceMarkerClick(mapMarker, position, name, zoom);
        });

        return mapMarker;
    }

    convertMapPositionToPixelPosition(position: LatLng): [number, number] {
        let mapBounds = this._map.getBounds();
        let mapProjection = this._map.getProjection();

        var scale = Math.pow(2, this._map.getZoom());
        var northWest = new google.maps.LatLng(mapBounds.getNorthEast().lat(), mapBounds.getSouthWest().lng());
        var worldCoordinateNorthWest = mapProjection.fromLatLngToPoint(northWest);
        var worldCoordinate = mapProjection.fromLatLngToPoint(position.toGoogle());
        var latLngOffset = new google.maps.Point(
            Math.floor((worldCoordinate.x - worldCoordinateNorthWest.x) * scale),
            Math.floor((worldCoordinate.y - worldCoordinateNorthWest.y) * scale)
        );

        return [latLngOffset.x, latLngOffset.y];
    }

    createSelectedBoundsRectangle(selectedBounds: Bounds): MapRectangle {
        return new MapRectangle(
            new google.maps.Rectangle({
                map: this._map,
                bounds: selectedBounds.toGoogle(),
                strokeColor: Colors.selectedBoundsRectangleStroke,
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillOpacity: 0,
                zIndex: MapConstants.zIndexForSelectedRangeRect
            })
        );
    }

    addOverlayImageMap() {
        if (!this.isOverlayImageMapEnabled) {
            return;
        }

        let opacity = this.overlayImageMapOpacity;
        let urlTemplate = this.overlayImageMapUrlTemplate;

        if (!OverlayImageMapSettingsDialogController.isValidImageMapTypeUrlTemplate(urlTemplate)) {
            return;
        }

        let overlayImageMapType = new google.maps.ImageMapType({
            getTileUrl: (coord, zoom) => {
                let tileUrl = urlTemplate.replace('{zoom}', zoom.toString());
                tileUrl = tileUrl.replace('{x}', coord.x.toString());
                tileUrl = tileUrl.replace('{y}', coord.y.toString());
                return tileUrl;
            },
            tileSize: new google.maps.Size(256, 256),
            name: Resources.text.map_type_overlay_image_map,
            opacity: opacity / 10.0,
            maxZoom: 15,
        });

        this._map.overlayMapTypes.insertAt(0, overlayImageMapType);

        if (this._map.getZoom() > overlayImageMapType.maxZoom) {
            this._map.setZoom(overlayImageMapType.maxZoom);
        }
    }

    removeOverlayImageMap() {
        for (let index = 0; index < this._map.overlayMapTypes.getLength(); index++) {
            let mapType = this._map.overlayMapTypes.getAt(index);
            if (mapType.name == Resources.text.map_type_overlay_image_map) {
                this._map.overlayMapTypes.removeAt(index);
                break;
            }
        }
    }

    shouldPolylineClick(position: LatLng): boolean {
        return true;
    }
}