export class AlertPanel {
    static create(message: string): HTMLElement {
        const template = document.createElement('template');
        template.innerHTML = `
<div class="alert-panel">
    <span class="alert-icon">⚠️</span>
    <span class="alert-message" id="alert-message"></span>
</div>`.trim();
        const div = template.content.firstChild as HTMLDivElement;
        const spanAlertMessage = div.getElementsByClassName('alert-message').item(0) as HTMLSpanElement;
        spanAlertMessage.textContent = message;
        return div;
    }
}