
import { HTMLUtility } from './Ridingazua.HTMLUtility';
import { Resources } from './Ridingazua.Resources';
import { ApplicationEvent, ApplicationEventListener, ApplicationState } from './Ridingazua.ApplicationState';
import { MapTypeHelper } from './Ridingazua.MapType';
import { MapController } from './Ridingazua.MapController';
import { KakaoMapWrapper } from './Ridingazua.KakaoMapWrapper';

/**
 * 상단에 카카오 로드뷰 버튼을 노출하기 위한 controller.
 */
export class KakaoRoadViewButtonController implements ApplicationEventListener {
    private static instance: KakaoRoadViewButtonController;
    private div: HTMLElement;

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        div.classList.add('box');
        div.classList.add('children-spacing');

        let button = HTMLUtility.createIconButton(
            Resources.text.toggle_overlay_kakao_roadview,
            'streetview',
            () => {
                this.toggleOverlayKakaoRoadView();
            }
        );

        div.appendChild(button);
        this.appendOrRemoveDiv();
        ApplicationState.addListener(this);
    }

    static createInstance(): KakaoRoadViewButtonController {
        if (!this.instance) {
            this.instance = new KakaoRoadViewButtonController();
        }

        return this.instance;
    }

    private toggleOverlayKakaoRoadView() {
        const map = ApplicationState.map;

        if (!(map instanceof KakaoMapWrapper)) {
            return;
        }

        const kakaoMapWrapper = map as KakaoMapWrapper;
        kakaoMapWrapper.toggleOverlayKakaoRoadView();
    }

    private appendOrRemoveDiv() {
        if (!MapTypeHelper.kakaoBasedTypes.includes(MapController.selectedMapType)) {
            this.div?.parentElement?.removeChild(this.div);
        } else {
            let divLeftTop = document.getElementById('div-left-bottom');
            divLeftTop.appendChild(this.div);
        }
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any | null): void {
        if (event != ApplicationEvent.SELECTED_MAP_TYPE_CHANGED) {
            return;
        }

        this.appendOrRemoveDiv();
    }
}