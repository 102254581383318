import { APIManager } from "./Ridingazua.APIManager";
import { Resources } from "./Ridingazua.Resources";
import { MapSection } from "../common/Ridingazua.Model";
import { LatLng, MapMarker, MapPolyline } from "./Ridingazua.MapWrapper";
import { ApplicationState } from "./Ridingazua.ApplicationState";

export class SegmentListInMapController {
    private static instance: SegmentListInMapController;

    private _items: MapSection[] = [];

    private get items(): MapSection[] {
        return this._items;
    }

    private set items(value: MapSection[]) {
        this._items = value || [];
        this.updateMapObjects();
    }

    private selectedItem?: MapSection;

    private static createInstance() {
        if (!this.instance) {
            this.instance = new SegmentListInMapController();
        }
    }

    static load() {
        this.createInstance();
        this.instance.load();
    }

    private async load() {
        try {
            let bounds = ApplicationState.map.bounds;
            this.items = await APIManager.loadSegmentList(bounds.north, bounds.east, bounds.south, bounds.west);
        } catch (error) {
            let errorMessage = APIManager.messageFromResponseError(error) || Resources.text.error_occured;
            toastr.error(errorMessage);
        }
    }

    private lines?: MapPolyline[];
    private markers?: MapMarker[];

    private updateMapObjects() {
        this.lines?.forEach(line => {
            line.map = null;
        });

        this.markers?.forEach(marker => {
            marker.map = null;
        });

        this.lines = [];
        this.markers = [];

        this.items.forEach(item => {
            this.addMapObjectForItem(item);
        });
    }

    private addMapObjectForItem(item: MapSection) {
        let map = ApplicationState.map;
        let points = item.points;
        let path = points.map(point => { return LatLng.fromPoint(point) });
        let lines = map.createSegmentPolylines(path, this.selectedItem == item);
        lines.forEach(line => {
            this.lines.push(line);
        });

        this.markers.push(
            map.createStartOrEndPointMarker(
                true,
                LatLng.fromPoint(points[0])
            )
        );

        this.markers.push(
            map.createStartOrEndPointMarker(
                false,
                LatLng.fromPoint(points[points.length - 1])
            )
        );
    }
}