import { isNothing } from '../common/Ridingazua.Utility';

export class HTMLUtility {
    static escapedEntity = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;', '/': '&#x2F;', '`': '&#x60;', '=': '&#x3D;' };

    static escapeHTMLText(text: string): string {
        return String(text)
            .fix()
            .replace(/[&<>"'`=\/]/g, (s) => {
                return this.escapedEntity[s];
            });
    }

    static findLinks(text: string): [number, string][] {
        let regExpForLink = new RegExp('(http|https)://([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-])?', 'g');
        let results: [number, string][] = [];

        while (true) {
            let execResult = regExpForLink.exec(text);
            if (!execResult) {
                break;
            }

            let linkUrl = execResult[0];
            results.push([regExpForLink.lastIndex - linkUrl.length, linkUrl]);
        }

        return results;
    }

    /**
     * 텍스트 내의 URL은 anchor 태그로 변경하고, HTML 관련 특수문자는 escape 처리하고, 줄바꿈은 br 처리하여 반환
     * @param text
     */
    static replaceToTextContent(text: string): string {
        // 링크 문자열 위치 파악 후, 임시 치환
        let links = HTMLUtility.findLinks(text);
        let result = text;
        for (let i = links.length - 1; i >= 0; i--) {
            let link = links[i];
            result = result.substring(0, link[0]) + `__link_${i}__` + result.substring(link[0] + link[1].length);
        }

        // HTML 특수문자 치환
        result = HTMLUtility.escapeHTMLText(result);

        // 줄바꿈 치환
        result = result.replace(/\n/g, '<br />');

        // 링크 임시 치환 부분을 anchor 로 치환
        for (let i = 0; i < links.length; i++) {
            let link = links[i];
            let url = link[1];
            let anchorElement = document.createElement('a');
            anchorElement.href = url;
            anchorElement.textContent = url;
            anchorElement.target = '_blank';
            result = result.replace(`__link_${i}__`, anchorElement.outerHTML);
        }

        return result;
    }

    /**
     * element를 containerElement의 x, y, z 좌표에 absolute position으로 배치한다.
     * @param element
     * @param containerElement
     * @param x
     * @param y
     * @param zIndex
     * @param margin
     */
    static showElementInContainer(element: HTMLElement, containerElement: Element, x: number, y: number, zIndex: number, margin: number = 0, anchor: string = 'left top') {
        element.style.position = 'absolute';
        element.style.zIndex = `${zIndex}`;
        element.style.visibility = 'hidden';

        if (element.parentElement !== containerElement) {
            containerElement.appendChild(element);
        }

        let containerRect = containerElement.getBoundingClientRect();
        let elementRect = element.getBoundingClientRect();

        margin = margin || 0;
        let elementLeft = x + margin;
        if (anchor.includes('right')) {
            elementLeft = x - elementRect.width - margin;
        }

        let elementTop = y + margin;
        if (anchor.includes('bottom')) {
            elementTop = y - elementRect.height - margin;
        }

        if (elementLeft + elementRect.width > containerRect.right) {
            elementLeft = x - margin - elementRect.width;
        }

        if (anchor.includes('right') && elementLeft < 0) {
            elementLeft = x + margin;
        }

        if (elementTop + elementRect.height > containerRect.bottom) {
            elementTop = y - margin - elementRect.height;
        }

        if (anchor.includes('bottom') && elementTop < 0) {
            elementTop = y + margin;
        }

        element.style.left = `${elementLeft}px`;
        element.style.top = `${elementTop}px`;
        element.style.visibility = '';
    }

    static createIconElement(iconName: string): HTMLElement {
        let i = document.createElement('i');
        i.classList.add('material-icons-outlined');
        i.textContent = iconName;
        return i;
    }

    /**
     * 아이콘 버튼을 생성한다.
     * @param title
     * @param iconName google material icon name
     * @param action
     */
    static createIconButton(title: string, iconName: string, action: (button: HTMLElement) => void): HTMLButtonElement {
        let button = document.createElement('button');
        button.classList.add('icon-button');
        button.title = title;

        button.appendChild(this.createIconElement(iconName));

        button.onclick = (event) => {
            event.stopPropagation();
            action(button);
        };

        return button;
    }

    static createIconAnchor(title: string, iconName: string, action: (anchor: HTMLElement) => void): HTMLAnchorElement {
        let anchor = document.createElement('a');
        anchor.appendChild(this.createIconElement(iconName));
        anchor.onclick = (event) => {
            event.stopPropagation();
            action(anchor);
        };
        return anchor;
    }

    /**
     * autofocus 속성이 설정된 input hidden 요소를 element에 추가한다.
     * JQuery UI dialog가 open 될 때, input text 요소에 autofocus가 들어가는 것을 방지하기 위해 사용된다.
     * @param element
     */
    static appendInputHiddenAutofocus(element: HTMLElement) {
        let input = document.createElement('input');
        input.type = 'hidden';
        input.setAttribute('autofocus', 'true');
        element.appendChild(input);
    }

    static drawRoundRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number, fill: boolean, stroke: boolean) {
        if (isNothing(stroke)) {
            stroke = true;
        }

        if (isNothing(radius)) {
            radius = 5;
        }

        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
        if (fill) {
            ctx.fill();
        }
        if (stroke) {
            ctx.stroke();
        }
    }

    static measureMutlineText(context: CanvasRenderingContext2D, text: string, lineHeight: number, lineSpacing: number): Rect {
        let lines = text.split('\n');
        let cursorY = 0;
        let result = new Rect(0, 0, 0, 0);
        for (let line of lines) {
            let width = context.measureText(line).width;
            let rect = new Rect(0, cursorY, width, lineHeight);
            result = result.union(rect);
            cursorY += lineHeight + lineSpacing;
        }
        return result;
    }

    static drawMutlineText(context: CanvasRenderingContext2D, text: string, lineHeight: number, lineSpacing: number, x: number, y: number) {
        let lines = text.split('\n');
        let cursorY = y;
        for (let line of lines) {
            context.fillText(line, x, cursorY + lineHeight);
            cursorY += lineHeight + lineSpacing;
        }
    }

    static resetDialogPositionToCenter(dialog: JQuery<HTMLElement>, delay: number = 0) {
        setTimeout(() => {
            if (window.innerWidth * 0.9 >= 500) {
                dialog.dialog('option', 'minWidth', 500);
            } else {
                dialog.dialog('option', 'width', window.innerWidth * 0.9);
            }
            dialog.dialog('option', 'maxHeight', window.innerHeight * 0.9);
            dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });
        }, delay);
    }
}

export class CheckboxController {
    private _title?: string;

    get title(): string {
        return this._title;
    }

    set title(value: string | null) {
        this._title = value;
        this.update();
    }

    private _isChecked = false;

    get isChecked(): boolean {
        return this._isChecked;
    }

    set isChecked(value: boolean) {
        this._isChecked = value;
        this.update();
    }

    onchange?: (checked: boolean) => void;

    div: HTMLDivElement;
    private i: HTMLElement;
    private label: HTMLLabelElement;

    private onclick() {
        this.isChecked = !this.isChecked;
        if (this.onchange) {
            this.onchange(this.isChecked);
        }
    }

    constructor() {
        this.div = document.createElement('div');
        this.div.classList.add('div-checkbox');
        this.div.classList.add('middle');

        let i = document.createElement('i');
        i.classList.add('material-icons-outlined');
        i.textContent = 'check_box';
        i.onclick = () => {
            this.onclick();
        };
        this.i = i;
        this.div.appendChild(i);

        let label = document.createElement('label');
        label.onclick = () => {
            this.onclick();
        };
        this.div.appendChild(label);
        this.label = label;
    }

    private update() {
        this.i.textContent = this.isChecked ? 'check_box' : 'check_box_outline_blank';
        this.label.textContent = this.title;
    }
}

export class Rect {
    x: number;
    y: number;
    width: number;
    height: number;

    get minX(): number {
        return this.x;
    }

    get maxX(): number {
        return this.x + this.width;
    }

    get minY(): number {
        return this.y;
    }

    get maxY(): number {
        return this.y + this.height;
    }

    get centerX(): number {
        return this.maxX - this.width / 2;
    }

    get centerY(): number {
        return this.maxY - this.height / 2;
    }

    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    union(rect: Rect): Rect {
        let x = Math.min(this.minX, rect.minX);
        let y = Math.min(this.minY, rect.minY);
        let width = Math.max(this.maxX, rect.maxX) - x;
        let height = Math.max(this.maxY, rect.maxY) - y;
        return new Rect(x, y, width, height);
    }

    intersect(rect: Rect): Rect | null {
        let x1 = Math.max(this.minX, rect.minX);
        let x2 = Math.min(this.maxX, rect.maxX);

        let y1 = Math.max(this.minY, rect.minY);
        let y2 = Math.min(this.maxY, rect.maxY);

        if (x2 >= x1 && y2 >= y1) {
            return new Rect(x1, y1, x2 - x1, y2 - y1);
        }

        return null;
    }

    contains(x: number, y: number): boolean {
        return this.minX <= x && x < this.maxX && this.minY <= y && y <= this.maxY;
    }
}
