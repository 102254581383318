import { ApplicationState } from './Ridingazua.ApplicationState';
import { SessionController } from './Ridingazua.SessionController';
import { Resources } from './Ridingazua.Resources';
import { Statics } from '../common/Ridingazua.Statics';
import { AdsController } from './Ridingazua.AdsController';
import { AdvertisementPosition } from '../common/Ridingazua.Model';
import { Log } from '../common/Ridingazua.Log';
import { CommonDialogController } from './Ridingazua.CommonDialogController';

export class LoginDialogController {
    private static instance?: LoginDialogController;

    private dialog: JQuery;
    private div: HTMLDivElement;
    private logoutButton: HTMLButtonElement;
    private loginButtons: HTMLElement[] = [];

    private onLoginSuccess?: () => void;

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        div.classList.add('center');
        div.classList.add('middle');

        this.addButtonGoogleLogin();
        this.addButtonKakaoLogin();
        this.addButtonFacebookLogin();
        this.addLinkPrivacyPolicy();

        let logoutButton = document.createElement('button');
        logoutButton.textContent = Resources.text.logout;
        logoutButton.onclick = () => {
            this.logout();
        };
        div.appendChild(logoutButton);
        this.logoutButton = logoutButton;

        this.dialog = $(div).dialog({
            title: Resources.text.login,
            modal: true,
            minHeight: 10,
            close: () => {
                this.onLoginSuccess = null;
            },
        });
    }

    private addButtonFacebookLogin() {
        let button = document.createElement('button');
        button.style.opacity = '30%';
        button.classList.add('login');

        button.onclick = () => {
            this.loginWithFacebook();
        };

        this.div.appendChild(button);

        let img = document.createElement('img');
        img.src = Statics.image('facebook_logo.png');
        img.alt = Resources.text.login_with_facebook;
        button.appendChild(img);

        let span = document.createElement('span');
        span.style.color = '#4267B2';
        span.textContent = Resources.text.login_with_facebook;
        button.appendChild(span);

        this.loginButtons.push(button);
    }

    private addButtonGoogleLogin() {
        let button = document.createElement('button');
        button.classList.add('login');

        button.onclick = () => {
            ApplicationState.google.accounts.id.prompt((notification) => {
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                    ApplicationState.google.accounts.id.prompt();
                }
            });

            this.dialog.dialog('close');
        };

        this.div.appendChild(button);

        let img = document.createElement('img');
        img.src = Statics.image('google_logo.png');
        img.alt = Resources.text.login_with_google;
        button.appendChild(img);

        let span = document.createElement('span');
        span.textContent = Resources.text.login_with_google;
        button.appendChild(span);

        this.loginButtons.push(button);
    }

    private addButtonKakaoLogin() {
        let button = document.createElement('button');
        button.classList.add('login');

        button.onclick = () => {
            this.loginWithKakao();
        };

        this.div.appendChild(button);

        let img = document.createElement('img');
        img.src = Statics.image('kakao_logo.png');
        img.alt = Resources.text.login_with_kakao;
        button.appendChild(img);

        let span = document.createElement('span');
        span.style.color = '#3C1E1F';
        span.textContent = Resources.text.login_with_kakao;
        button.appendChild(span);

        this.loginButtons.push(button);
    }

    private addLinkPrivacyPolicy() {
        let p = document.createElement('p');
        this.div.appendChild(p);

        let anchor = document.createElement('a');
        anchor.href = Statics.editorUrl + '/privacy.html';
        anchor.textContent = Resources.text.privacy_policy;
        anchor.target = '_blank';
        p.appendChild(anchor);
    }

    static show(onLoginSuccess?: () => void | null) {
        if (!this.instance) {
            this.instance = new LoginDialogController();
        }

        this.instance.onLoginSuccess = onLoginSuccess;

        if (ApplicationState.user) {
            this.instance.dialog.dialog('option', 'title', Resources.text.logout);
            this.instance.loginButtons.forEach((button) => {
                $(button).hide();
            });
            $(this.instance.logoutButton).show();
        } else {
            this.instance.dialog.dialog('option', 'title', Resources.text.login);
            this.instance.loginButtons.forEach((button) => {
                $(button).show();
            });
            $(this.instance.logoutButton).hide();
        }

        AdsController.createInstance().replaceAdForDialogWithAdPosition(AdvertisementPosition.LOGIN_DIALOG, this.instance.div, this.instance.dialog);
        this.instance.resetSize();
        this.instance.dialog.dialog('open');
    }

    private resetSize() {
        if (!this.dialog) {
            return;
        }

        this.dialog.dialog('option', 'width', Math.min(window.innerWidth * 0.95, AdsController.dialogWidthForAds));
        this.dialog.dialog('option', 'maxWidth', window.innerWidth * 0.95);
        this.dialog.dialog('option', 'height', 'auto');
    }

    private async loginWithFacebook() {
        CommonDialogController.showConfirm(
            Resources.text.important,
            Resources.text.facebook_login_deprecated,
            [
                {
                    text: Resources.text.ok
                }
            ]
        );
    }

    private async loginWithKakao() {
        try {
            await SessionController.loginWithKakao();

            if (this.onLoginSuccess) {
                this.onLoginSuccess();
            }
        } catch (error) {
            Log.e(error);
            toastr.error(Resources.text.failed_to_login_with_kakao);
        } finally {
            this.dialog.dialog('close');
        }
    }

    private logout() {
        SessionController.logout();
        this.dialog.dialog('close');
    }
}
