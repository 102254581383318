import { Colors } from "../common/Ridingazua.Colors";
import { Bounds, Point, Section } from "../common/Ridingazua.Model";
import { Statics } from "../common/Ridingazua.Statics";
import { Utility } from "../common/Ridingazua.Utility";
import { ApplicationEvent, ApplicationState } from "./Ridingazua.ApplicationState";
import { KakaoRoadViewDialogController } from "./Ridingazua.KakaoRoadViewDialogController";
import { MapConstants } from "./Ridingazua.MapConstants";
import { MapController } from "./Ridingazua.MapController";
import { MapType, MapTypeHelper } from "./Ridingazua.MapType";
import { MapMarker, LatLng, LatLngBounds, MapProvider, MapWrapper, MapPolyline, MapRectangle } from "./Ridingazua.MapWrapper";
import { OverlayImageMapSettingsDialogController } from "./Ridingazua.OverlayImageMapSettingsDialogController";
import { WaypointDialogContorller } from "./Ridingazua.WaypointDialogController";

/**
 * 카카오 지도를 제공하기 위한 wrapper
 */
export class KakaoMapWrapper extends MapWrapper {
    private _map: kakao.maps.Map;
    private _div: HTMLElement;

    constructor() {
        super();

        let divMap = document.getElementById('div-map');
        this._div = divMap;

        // 기본 초기 위치 반포한강공원
        let centerZoom = this.loadMapCenterZoomFromStorage();
        let latitude = centerZoom?.latitude || 37.512926;
        let longitude = centerZoom?.longitude || 127.002085;
        let zoom = centerZoom?.zoom || 10;
        let mapTypeId = MapTypeHelper.kakaoMapTypeId(MapController.selectedMapType);

        this._map = new kakao.maps.Map(
            divMap,
            {
                center: new LatLng(latitude, longitude).toKakao(),
                mapTypeId: mapTypeId,
                level: this.zoomToLevel(zoom, mapTypeId),
                scrollwheel: true,
                tileAnimation: true
            }
        );

        this._map.setCursor('crosshair');
        this._map.addOverlayMapTypeId(kakao.maps.MapTypeId.BICYCLE_HYBRID);

        // this.addOsmTypeToMap();
        // this.addOverlayImageMap();

        this.addListeners();
    }

    private addListeners() {
        kakao.maps.event.addListener(
            this._map,
            'click',
            (mouseEvent) => {
                let position = LatLng.fromKakao(mouseEvent.latLng);
                this.onSomethingClick(position);
                kakao.maps.event.preventMap();
            }
        );

        kakao.maps.event.addListener(
            this._map,
            'rightclick',
            (mouseEvent) => {
                let position = LatLng.fromKakao(mouseEvent.latLng);
                this.onSomethingRightClick(position);
                kakao.maps.event.preventMap();
            }
        );

        kakao.maps.event.addListener(
            this._map,
            'center_changed',
            () => {
                this.onCenterChanged();
            }
        );

        kakao.maps.event.addListener(
            this._map,
            'idle',
            () => {
                this.onIdle();
            }
        );

        kakao.maps.event.addListener(
            this._map,
            'zoom_changed',
            () => {
                this.onZoomChanged();
            }
        );

        kakao.maps.event.addListener(
            this._map,
            'dragstart',
            () => {
                this.onDragStart();
            }
        );

        kakao.maps.event.addListener(
            this._map,
            'mousemove',
            (mouseEvent) => {
                let latLng = mouseEvent.latLng;

                let virtualPoint = this.nearCourseVirtualPointFrom(
                    LatLng.fromKakao(latLng)
                );

                this.showCursorMarker(virtualPoint);
            }
        );
    }

    get mapProvider(): MapProvider {
        return MapProvider.KAKAKO;
    }

    get map(): any {
        return this._map;
    }

    get div(): Element {
        return this._div;
    }

    set mapType(mapType: MapType) {
        let mapTypeId = MapTypeHelper.kakaoMapTypeId(mapType);
        this._map.setMapTypeId(mapTypeId);
    }

    get availableMapTypes(): MapType[] {
        return [
            MapType.KAKAO_ROADMAP,
            MapType.KAKAO_SKYVIEW
        ];
    }

    get center(): LatLng {
        return LatLng.fromKakao(
            this._map.getCenter()
        );
    }

    set center(latLng: LatLng) {
        this._map.setCenter(
            latLng.toKakao()
        );
    }

    private MAX_LEVEL = 14;

    private levelToZoom(level: number): number {
        return this.MAX_LEVEL - level;
    }

    private zoomToLevel(zoom: number, mapTypeId: kakao.maps.MapTypeId): number {
        let level = Math.abs(zoom - this.MAX_LEVEL);
        let minLevel = 0;

        switch (mapTypeId) {
            case kakao.maps.MapTypeId.ROADMAP:
                minLevel = 1;
                break;
            case kakao.maps.MapTypeId.SKYVIEW:
                minLevel = 0;
                break;
        }

        level = Math.max(level, minLevel);
        return level;
    }

    get defaultZoomToPoint(): number {
        return 9;
    }

    get zoom(): number {
        return this.levelToZoom(this._map.getLevel());
    }

    set zoom(zoom: number) {
        this._map.setLevel(
            this.zoomToLevel(
                zoom,
                this._map.getMapTypeId()
            ),
            { animate: true }
        );
    }

    get bounds(): LatLngBounds {
        return LatLngBounds.fromKakao(
            this._map.getBounds()
        );
    }

    set bounds(bounds: LatLngBounds) {
        this._map.setBounds(
            bounds.toKakao()
        );
    }

    pan(position: LatLng) {
        this._map.panTo(
            position.toKakao()
        );
    }

    jump(position: LatLng, zoom: number) {
        this._map.jump(
            position.toKakao(),
            this.zoomToLevel(
                zoom,
                this._map.getMapTypeId()
            ),
            {
                animate: true
            }
        )
    }

    setMyLocationMarker(position?: LatLng) {
        this.removeMyLocationMarker();

        if (!position) {
            return;
        }

        let image = new kakao.maps.MarkerImage(
            Statics.image('my_location.png'),
            new kakao.maps.Size(10, 10),
            {
                offset: new kakao.maps.Point(5, 5),
            }
        )

        let marker = new kakao.maps.Marker({
            map: this._map,
            center: position.toKakao(),
            image: image,
            draggable: false,
            clickable: true,
            zIndex: MapConstants.zIndexForMyLocationMarker
        });

        kakao.maps.event.addListener(marker, 'click', (mouseEvent) => {
            this.removeMyLocationMarker();
            kakao.maps.event.preventMap();
        });

        this.myLocationMarker = new MapMarker(marker);
    }

    setCursorMarker(position?: LatLng) {
        if (this.cursorMarker) {
            if (position == null) {
                this.cursorMarker.map = null;
            } else {
                this.cursorMarker.map = this;
                this.cursorMarker.position = position;;
            }
            return;
        }

        if (!position) {
            return;
        }

        let image = new kakao.maps.MarkerImage(
            Statics.image('start_marker.png'),
            new kakao.maps.Size(10, 10),
            {
                offset: new kakao.maps.Point(5, 5),
            }
        );

        let cursorMarker = new kakao.maps.Marker({
            map: this._map,
            position: position.toKakao(),
            image: image,
            draggable: false,
            clickable: true,
            zIndex: MapConstants.zIndexForCursorMarker,
        });

        kakao.maps.event.addListener(cursorMarker, 'click', (mouseEvent) => {
            this.onCursorMarkerClick();
            kakao.maps.event.preventMap();
        });

        kakao.maps.event.addListener(cursorMarker, 'rightclick', (mouseEvent) => {
            this.onCursorMarkerRightClick();
            kakao.maps.event.preventMap();
        });

        this.cursorMarker = new MapMarker(cursorMarker);
        this.cursorMarker.cursor = 'crosshair';
    }

    createStartOrEndPointMarker(isStart: boolean, position: LatLng): MapMarker {
        let iconUrl = isStart ? Statics.image('start_marker.png') : Statics.image('end_marker.png');

        let image = new kakao.maps.MarkerImage(
            iconUrl,
            new kakao.maps.Size(10, 10),
            {
                offset: new kakao.maps.Point(5, 5),
            }
        );

        return new MapMarker(
            new kakao.maps.Marker({
                map: this._map,
                position: position.toKakao(),
                image: image,
                zIndex: MapConstants.zIndexForStartFinishMarker,
                opacity: this.markerOpacity()
            })
        );
    }

    createWaypointMarker(point: Point): MapMarker {
        let waypoint = point.waypoint;

        let iconImageName = waypoint.type.tag
            .replace(/\s+/g, '_')
            .toLowerCase();

        let image = new kakao.maps.MarkerImage(
            Statics.image(`waypoint_icon/pin/${iconImageName}.png`),
            new kakao.maps.Size(32, 32),
        );

        const marker = new kakao.maps.Marker({
            map: this._map,
            position: LatLng.fromPoint(point).toKakao(),
            title: waypoint.name,
            image: image,
            zIndex: MapConstants.zIndexForWaypointMarker,
            draggable: false,
            clickable: true,
            opacity: this.markerOpacity()
        });

        kakao.maps.event.addListener(marker, 'click', () => {
            WaypointDialogContorller.showUsingPoint(point);
            kakao.maps.event.preventMap();
        });

        return new MapMarker(marker);
    }

    createSelectedRangePolylines(path: LatLng[], section: Section): MapPolyline[] {
        let backgroundPolyline = new kakao.maps.Polyline({
            map: this._map,
            path: path.map((position) => { return position.toKakao() }),
            strokeColor: Colors.coursePolylineStroke,
            strokeOpacity: this.polylineStrokeOpacity(true),
            strokeStyle: 'solid',
            strokeWeight: 5,
            zIndex: MapConstants.zIndexForSelectedRange,
        });

        let actualPolyline = new kakao.maps.Polyline({
            map: this._map,
            path: path.map((position) => { return position.toKakao() }),
            strokeColor: Colors.courseSelectedRangePolylineFill,
            strokeOpacity: this.polylineStrokeOpacity(true),
            strokeStyle: 'solid',
            strokeWeight: 1,
            zIndex: MapConstants.zIndexForSelectedRange,
        });

        let polylines = [backgroundPolyline, actualPolyline];

        polylines.forEach(polyline => {
            kakao.maps.event.addListener(polyline, 'mousemove', (mouseEvent) => {
                let latLng = mouseEvent.latLng;
                this.onPolylineMousemove(
                    section,
                    LatLng.fromKakao(latLng)
                );
                kakao.maps.event.preventMap();
            });

            kakao.maps.event.addListener(polyline, 'mouseout', () => {
                this.onPolylineMouseout();
                kakao.maps.event.preventMap();
            });

            kakao.maps.event.addListener(polyline, 'click', (mouseEvent) => {
                this.onPolylineClick(
                    LatLng.fromKakao(mouseEvent.latLng)
                )
                kakao.maps.event.preventMap();
            });

            kakao.maps.event.addListener(polyline, 'rightclick', (mouseEvent) => {
                this.onPolylineRightClick(
                    LatLng.fromKakao(mouseEvent.latLng)
                )
                kakao.maps.event.preventMap();
            });
        });

        return [
            new MapPolyline(backgroundPolyline),
            new MapPolyline(actualPolyline)
        ];
    }

    createLoadingPolyline(path: LatLng[], isSelected: boolean): MapPolyline {
        return new MapPolyline(
            new kakao.maps.Polyline({
                map: this._map,
                path: path.map((position) => { return position.toKakao() }),
                strokeColor: Colors.loadingPolyline,
                strokeWeight: 2,
                strokeOpacity: this.polylineStrokeOpacity(isSelected),
                strokeStyle: 'dash',
            })
        );
    }

    createDirectionPolylines(path: LatLng[], isSelected: boolean): MapPolyline[] {
        let backgroundPolyline = new MapPolyline(
            new kakao.maps.Polyline({
                map: this._map,
                strokeColor: Colors.coursePolylineStroke,
                strokeOpacity: this.polylineStrokeOpacity(isSelected),
                strokeWeight: 5,
                path: path.map((position) => { return position.toKakao() }),
                zIndex: (
                    isSelected
                        ? MapConstants.zIndexForSelectedSection
                        : MapConstants.zIndexForDeselectedSection
                ),
            })
        );

        let actualPolyline = new MapPolyline(
            new kakao.maps.Polyline({
                map: this._map,
                strokeColor: Colors.coursePolylineFill,
                strokeOpacity: this.polylineStrokeOpacity(isSelected),
                strokeWeight: 1,
                path: path.map((position) => { return position.toKakao() }),
                zIndex: (
                    isSelected
                        ? MapConstants.zIndexForSelectedSection
                        : MapConstants.zIndexForDeselectedSection
                ),
            })
        );

        return [backgroundPolyline, actualPolyline];
    }

    convertMapPositionToPixelPosition(position: LatLng): [number, number] {
        let mapProjection = this._map.getProjection();
        let point = mapProjection.containerPointFromCoords(
            position.toKakao()
        );
        return [point.x, point.y];
    }

    createSegmentPolylines(path: LatLng[], isSelected: boolean): MapPolyline[] {
        let backgroundPolyline = new MapPolyline(
            new kakao.maps.Polyline({
                map: this._map,
                strokeColor: Colors.coursePolylineStroke,
                strokeOpacity: this.polylineStrokeOpacity(isSelected),
                strokeWeight: 1,
                zIndex: (
                    isSelected
                        ? MapConstants.zIndexForSelectedMapSection
                        : MapConstants.zIndexForMapSection
                ),
                path: path.map(position => position.toKakao()),
            })
        );

        let actualPolyline = new MapPolyline(
            new kakao.maps.Polyline({
                map: this._map,
                strokeColor: Colors.courseSelectedRangePolylineFill,
                strokeOpacity: this.polylineStrokeOpacity(isSelected),
                strokeWeight: 1,
                zIndex: (
                    isSelected
                        ? MapConstants.zIndexForSelectedMapSection
                        : MapConstants.zIndexForMapSection
                ),
                path: path.map(position => position.toKakao()),
            })
        );

        return [backgroundPolyline, actualPolyline];
    }

    createPlaceMarker(name: string, index: number, position: LatLng, placeId?: string): MapMarker {
        let kakaoMarker = new kakao.maps.Marker({
            map: this._map,
            position: position.toKakao(),
            title: name,
            zIndex: (MapConstants.zIndexForPlaceMarker + 100 - index), // index가 낮을수록 더 높은 zIndex를 가진다.
        });

        let mapMarker = new MapMarker(kakaoMarker, placeId);

        kakao.maps.event.addListener(kakaoMarker, 'click', () => {
            let zoom = Math.max(this.defaultZoomToPoint, this.zoom);
            this.onPlaceMarkerClick(mapMarker, position, name, zoom);
            kakao.maps.event.preventMap();
        });

        return mapMarker;
    }

    createSelectedBoundsRectangle(selectedBounds: Bounds): MapRectangle {
        return new MapRectangle(
            new kakao.maps.Rectangle({
                map: this._map,
                bounds: selectedBounds.toKakao(),
                strokeColor: Colors.selectedBoundsRectangleStroke,
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillOpacity: 0,
                zIndex: MapConstants.zIndexForSelectedRangeRect
            })
        );
    }

    addOverlayImageMap() {
        if (!this.isOverlayImageMapEnabled) {
            return;
        }

        // let opacity = this.overlayImageMapOpacity;
        let maxZoom = 15;

        if (!this.isOverlayImageMapTileSetAdded) {
            this.addOverlayImageMapTileset(maxZoom);
        }

        if (this.zoom > maxZoom) {
            this.zoom = maxZoom;
        }

        this._map.addOverlayMapTypeId(kakao.maps.MapTypeId.OVERLAYIMAGEMAP);
    }

    private isOverlayImageMapTileSetAdded = false;

    private addOverlayImageMapTileset(maxZoom: number) {
        let urlTemplate = this.overlayImageMapUrlTemplate;

        if (!OverlayImageMapSettingsDialogController.isValidImageMapTypeUrlTemplate(urlTemplate)) {
            return;
        }

        let tileSet = new kakao.maps.Tileset(
            256,
            256,
            (x, y, z) => {
                let tileUrl = urlTemplate.replace('{zoom}', z.toString());
                tileUrl = tileUrl.replace('{x}', x.toString());
                tileUrl = tileUrl.replace('{y}', y.toString());
                return tileUrl;
            },
            [],
            false,
            0,
            maxZoom
        );

        kakao.maps.Tileset.add('OVERLAYIMAGEMAP', tileSet); // 'OVERLAY' 문자열은 kakao.maps.MapTypeId.OVERLAY 로 사용된다.
        this.isOverlayImageMapTileSetAdded = true;
    }

    removeOverlayImageMap() {
        this._map.removeOverlayMapTypeId(kakao.maps.MapTypeId.OVERLAYIMAGEMAP);
    }

    private onSomethingClick(position: LatLng) {
        if (this.isRoadViewOverlayed) {
            this.showRoadViewDialog(position);
            return;
        }

        let pixelPosition = this.convertMapPositionToPixelPosition(position);

        if (this.myLocationMarker) {
            let myLocationMarkerPixelPosition = this.convertMapPositionToPixelPosition(this.myLocationMarker.position);
            let isNearMyLocationMarker = Utility.pointsDistance(
                pixelPosition[0],
                pixelPosition[1],
                myLocationMarkerPixelPosition[0],
                myLocationMarkerPixelPosition[1]
            ) <= 10;

            if (isNearMyLocationMarker) {
                this.removeMyLocationMarker();
                return;
            }
        }

        let virtualPoint = this.nearCourseVirtualPointFrom(position);

        if (virtualPoint) {
            let waypoint = this.nearWaypointFrom(position);
            if (waypoint) {
                this.onWaypointClick(waypoint);
            } else {
                this.onPolylineClick(position);
            }

            return
        }

        this.onMapClick(position);
    }

    onMapClick(position: LatLng): void {
        if (this.isRoadViewOverlayed) {
            this.showRoadViewDialog(position);
        } else {
            super.onMapClick(position);
        }
    }

    onPolylineClick(position: LatLng): void {
        if (this.isRoadViewOverlayed) {
            this.showRoadViewDialog(position);
        } else {
            super.onPolylineClick(position);
        }
    }

    shouldPolylineClick(position: LatLng): boolean {
        if (this.isRoadViewOverlayed) {
            this.showRoadViewDialog(position);
            return false
        }

        return true;
    }

    private onSomethingRightClick(position: LatLng) {
        let virtualPoint = this.nearCourseVirtualPointFrom(position);

        if (virtualPoint) {
            this.onPolylineRightClick(position);
            return
        }

        this.onMapRightClick(position);
    }

    private isRoadViewOverlayed = false;

    toggleOverlayKakaoRoadView() {
        if (this.isRoadViewOverlayed) {
            this._map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);
            KakaoRoadViewDialogController.getInstance().close();
        } else {
            this._map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);
        }

        this.isRoadViewOverlayed = !this.isRoadViewOverlayed;
        ApplicationState.executeListeners(ApplicationEvent.UPDATE_LINES_REQUESTED);
    }

    private showRoadViewDialog(position: LatLng) {
        const kakaoRoadViewDialogController = KakaoRoadViewDialogController.getInstance();
        kakaoRoadViewDialogController.open(this, position);
    }

    private polylineStrokeOpacity(isSelected: Boolean): number {
        return this.isRoadViewOverlayed ? 0.5 : (isSelected ? 1 : 0.5);
    }

    private markerOpacity(): number {
        return this.isRoadViewOverlayed ? 0.5 : 1.0;
    }
}