export const MapConstants = {
    zIndexForSelectedRangeRect: 3,
    zIndexForSelectedSection: 5,
    zIndexForDeselectedSection: 4,
    zIndexForSelectedRange: 6,
    zIndexForMapSection: 7,
    zIndexForSelectedMapSection: 8,
    zIndexForStartFinishMarker: 7000,
    zIndexForInstructionMarker: 7100,
    zIndexForCursorMarker: 7200,
    zIndexForWaypointMarker: 7300,
    zIndexForPlaceMarker: 7400,
    zIndexForMyLocationMarker: 7500,
    zIndexForSelectedPlaceMarker: 7600,
    zIndexForPlaceMarkerInfoWindow: 7700,
    zIndexForSelectedRangeInfoLayer: 5900,
    zIndexForCursorInfoLayer: 6000
}