import { ApplicationEvent, ApplicationEventListener, ApplicationState } from "./Ridingazua.ApplicationState";
import { KakaoMapWalker } from "./Ridingazua.KakaoMapWalker";
import { KakaoMapWrapper } from "./Ridingazua.KakaoMapWrapper";
import { LatLng } from "./Ridingazua.MapWrapper";
import { Resources } from "./Ridingazua.Resources";

export class KakaoRoadViewDialogController implements ApplicationEventListener {
    private static instance?: KakaoRoadViewDialogController;

    private dialog: JQuery;
    private div: HTMLDivElement;
    private divRoadViewContainer: HTMLDivElement;
    private pMessage: HTMLParagraphElement;
    private roadView: kakao.maps.Roadview;
    private roadViewClient: kakao.maps.RoadviewClient;

    private mapWrapper: KakaoMapWrapper | null;

    private get map(): kakao.maps.Map {
        return this.mapWrapper.map;
    }

    private _mapWalker?: KakaoMapWalker;

    private isSmallScreen: boolean;

    private set mapWalker(mapWalker: KakaoMapWalker) {
        if (this._mapWalker) {
            this._mapWalker.map = null;
        }

        this._mapWalker = mapWalker;
    }

    private constructor() {
        let div = document.createElement('div');
        this.div = div;
        this.div.style.padding = '0';

        this.resetDivRoadViewContainer();

        this.pMessage = document.createElement('p');
        this.pMessage.style.padding = '10px';
        this.pMessage.style.position = 'absolute';
        this.pMessage.style.top = '50%';
        this.pMessage.style.width = '100%';
        this.pMessage.style.textAlign = 'center';
        this.pMessage.style.transform = 'translate(0, -50%)';

        this.dialog = $(div).dialog({
            title: Resources.text.kakao_road_view,
            create: (_, ui) => {
                $(this).find(".ui-dialog-content").css("padding", "0");
            },
            close: () => {
                this.mapWrapper = null;
                this.mapWalker = null;
            }
        });

        ApplicationState.addListener(this);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any | null): void {
        switch (event) {
            case ApplicationEvent.SELECTED_BASE_MAP_CHANGED:
                this.close();
                break;
            default:
                break;
        }
    }

    static getInstance(): KakaoRoadViewDialogController {
        if (!this.instance) {
            this.instance = new KakaoRoadViewDialogController();
        }

        return this.instance;
    }

    open(map: KakaoMapWrapper, position: LatLng) {
        this.isSmallScreen = window.innerWidth < 800;
        this.dialog.dialog('open');
        this.setPositionAndSizeIfNeeded();
        this.mapWrapper = map;
        this.mapWalker = new KakaoMapWalker(this.map, position);

        this.resetDivRoadViewContainer();
        this.showMessage(Resources.text.kakao_road_view_loading);

        this.roadViewClient.getNearestPanoId(
            position.toKakao(),
            50,
            panoId => {
                if (!panoId) {
                    this.showMessage(Resources.text.kakao_road_view_not_found);
                    return;
                }

                this.hideErrorMessage();

                this.roadView.setPanoId(
                    panoId,
                    position.toKakao()
                );
            }
        );

        this.adujstMapCenter(position);
    }

    private resetDivRoadViewContainer() {
        let old = this.divRoadViewContainer;
        this.divRoadViewContainer = document.createElement('div');
        this.divRoadViewContainer.style.width = '100%';
        this.divRoadViewContainer.style.height = '100%';

        if (old) {
            this.div.replaceChild(this.divRoadViewContainer, old);
        } else {
            this.div.appendChild(this.divRoadViewContainer);
        }

        this.resetRoadView();
    }

    private resetRoadView() {
        const roadView = new kakao.maps.Roadview(this.divRoadViewContainer);
        this.roadView = roadView;

        const roadViewClient = new kakao.maps.RoadviewClient();
        this.roadViewClient = roadViewClient;

        this.addEventListenersToRoadView();
    }

    private isPositionAndSizeNeeded = true;

    private setPositionAndSizeIfNeeded() {
        if (!this.isPositionAndSizeNeeded) {
            return;
        }

        let width = window.innerWidth * 0.5;
        let height = window.innerHeight * 0.5;
        let position = 'right bottom';

        if (this.isSmallScreen) {
            width = window.innerWidth - 10;
            height = window.innerHeight * 0.5;
            position = 'center bottom';
        }

        this.dialog.dialog(
            {
                width: width,
                height: height,
                position: {
                    my: position,
                    at: position,
                    of: window
                },
            }
        );

        this.isPositionAndSizeNeeded = false;
    }

    private showMessage(message: string) {
        this.pMessage.textContent = message;
        this.div.appendChild(this.pMessage);
        this.divRoadViewContainer.style.display = 'none';
    }

    private hideErrorMessage() {
        if (this.pMessage.parentNode != null) {
            this.div.removeChild(this.pMessage);
        }
        this.divRoadViewContainer.style.display = '';
    }

    private adujstMapCenter(position: LatLng) {
        let currentBounds = this.mapWrapper.bounds;

        let padLatitude = 0;
        let padLongitude = ((currentBounds.east - currentBounds.west) * 0.5) / 2;


        if (this.isSmallScreen) {
            padLatitude = -(((currentBounds.north - currentBounds.south) * 0.5) / 3);
            padLongitude = 0;
        }

        let positionToPan = new LatLng(
            position.latitude + padLatitude,
            position.longitude + padLongitude,
        );

        this.mapWrapper.pan(positionToPan);
    }

    private addEventListenersToRoadView() {
        kakao.maps.event.addListener(this.roadView, 'viewpoint_changed', () => {
            if (!this.map) {
                return;
            }

            var viewpoint = this.roadView.getViewpoint();
            this._mapWalker.angle = viewpoint.pan;
        });

        kakao.maps.event.addListener(this.roadView, 'position_changed', () => {
            if (!this.map) {
                return;
            }

            const position = this.roadView.getPosition();
            this._mapWalker.position = LatLng.fromKakao(position);
        });
    }

    close() {
        this.dialog.dialog('close');
    }
}
