import { ApplicationEvent, ApplicationState } from "./Ridingazua.ApplicationState";
import { AutoRouteDirector, DirectDirector, Director } from "./Ridingazua.Director";
import { StorageController } from "./Ridingazua.StorageController";

export class DirectionController {
    private static _selectedDirector?: Director;

    static get selectedDirector(): Director {
        if (!this._selectedDirector) {
            switch (StorageController.get('selectedDirector')) {
                case AutoRouteDirector.instance.name:
                    this._selectedDirector = AutoRouteDirector.instance;
                    break;
                case DirectDirector.instance.name:
                    this._selectedDirector = DirectDirector.instance;
                    break;
                default:
                    this._selectedDirector = AutoRouteDirector.instance;
                    break;
            }
        }

        return this._selectedDirector || AutoRouteDirector.instance;
    }

    static set selectedDirector(value: Director) {
        this._selectedDirector = value;
        StorageController.set('selectedDirector', value.name);
        ApplicationState.executeListeners(ApplicationEvent.SELECTED_DIRECTOR_CHANGED);
    }
}