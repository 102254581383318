import { SectionEditor } from "./Ridingazua.SectionEditor";

/**
 * 작업의 실행, 실행취소(undo), 다시실행(redo)를 관리해줍니다.
 */
export class TaskManager {
    static tasksProgressing: Task[] = [];
    static tasksForUndo: Task[] = [];
    static tasksForRedo: Task[] = [];
    static didTaskDeniedByLockedEditor:() => void;

    static doTask(task: Task) {
        let isIgnoreSectionEditorLock = this.isIgnoreSectionEditorLock(task);

        if(SectionEditor.isLocked && !isIgnoreSectionEditorLock) {
            this.didTaskDeniedByLockedEditor();
            return;
        }

        task.do();
        this.tasksForUndo.push(task);
        this.tasksForRedo.splice(0);
    }

    static undoTask() {
        if (this.tasksForUndo.length == 0) {
            return;
        }

        let task = this.tasksForUndo.pop();
        task.undo();

        this.tasksForRedo.push(task);
    }

    static redoTask() {
        if (this.tasksForRedo.length == 0) {
            return;
        }
        
        let task = this.tasksForRedo.pop();
        task.do();

        this.tasksForUndo.push(task);
    }

    static isIgnoreSectionEditorLock(task: Task): task is IgnoreSectionEditorLockTask {
        return (task as IgnoreSectionEditorLockTask).ignoreSectionEditorLock === true;
    }
}

/**
 * 실행취소(undo)와 다시실행(do)이 가능해야하는 작업은 이 interface를 활용하여 구현해야합니다.
 */
export interface Task {
    do(): void;
    undo(): void;
}

/**
 * 이 interface를 적용하면 sectionEditorLock을 무시하고 진행할수 있도록 설정 가능합니다.
 */
export interface IgnoreSectionEditorLockTask extends Task {
    ignoreSectionEditorLock: boolean;
}