import { isNothing, Utility } from '../common/Ridingazua.Utility';
import { Course, Point, Waypoint, Section, AdvertisementPosition } from '../common/Ridingazua.Model';
import { TaskManager, Task, IgnoreSectionEditorLockTask } from './Ridingazua.TaskManager';
import { ApplicationState, ApplicationEvent } from './Ridingazua.ApplicationState';
import { CommonDialogController } from './Ridingazua.CommonDialogController';
import { Resources } from './Ridingazua.Resources';
import { MapController } from './Ridingazua.MapController';
import { AdsController } from './Ridingazua.AdsController';

export class ImportDialogController {
    private static instance: ImportDialogController;

    private dialog: JQuery;
    private div: HTMLDivElement;
    private inputFile: HTMLInputElement;

    constructor() {
        let div = document.createElement('div');
        this.div = div;

        let inputFile = document.createElement('input');
        inputFile.accept = 'application/gpx+xml,application/vnd.garmin.tcx+xml,.gpx,.tcx';
        if(this.isIOS()) { inputFile.accept = ''; }
        inputFile.classList.add('border');
        inputFile.style.padding = '5px';
        inputFile.style.width = 'calc(100% - 12px)';

        inputFile.type = 'file';
        div.appendChild(inputFile);
        this.inputFile = inputFile;

        this.dialog = $(div).dialog({
            title: Resources.text.import,
            modal: true,
            buttons: [
                {
                    text: Resources.text.new_course_from_file,
                    click: () => {
                        this.importAsNewCourse();
                    },
                },
                {
                    text: Resources.text.new_section_from_file,
                    click: () => {
                        this.importFile(true);
                        this.dialog.dialog('close');
                    },
                },
            ],
            open: () => {
                this.reset();
            },
            close: () => {
                this.reset();
            },
        });
    }

    private isIOS(): boolean {
        return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    }

    static show() {
        if (!this.instance) {
            this.instance = new ImportDialogController();
        }
        AdsController.createInstance().replaceAdForDialogWithAdPosition(AdvertisementPosition.IMPORT_DIALOG, this.instance.div, this.instance.dialog);
        this.instance.resetSize();
        this.instance.dialog.dialog('open');
    }

    private resetSize() {
        if (!this.dialog) {
            return;
        }

        this.dialog.dialog('option', 'width', Math.min(window.innerWidth * 0.95, AdsController.dialogWidthForAds));
        this.dialog.dialog('option', 'maxWidth', window.innerWidth * 0.95);
        this.dialog.dialog('option', 'height', 'auto');
    }

    private reset() {
        this.inputFile.value = null;
    }

    private importAsNewCourse() {
        let action = () => {
            this.importFile(false);
            this.dialog.dialog('close');
        };

        if (ApplicationState.isSaveRequired) {
            CommonDialogController.showConfirm(Resources.text.new_course_from_file, Resources.text.confirm_message_for_lost, [
                {
                    text: Resources.text.ok,
                    action: () => {
                        action();
                    },
                },
                {
                    text: Resources.text.cancel,
                    action: () => {
                        // do nothing
                    },
                },
            ]);
        } else {
            action();
        }
    }

    /**
     * 파일을 임포트 한다.
     * @param addNewSections 이 값이 true일 경우, 임포트한 데이터를 새 섹션으로 추가한다. false일 경우, 임포트한 데이터를 이용해 새 코스를 시작한다.
     */
    private importFile(addNewSections: boolean) {
        // 현재 작업중인 코스가 섹션이 1개이며, 실제로는 아무것도 그려지지 않았다면
        // 임포트한 데이터를 이용해 무조건 새 코스를 만든다.
        let currentCourse = ApplicationState.course;
        if(currentCourse.sections.length <= 1 && currentCourse.allPoints().length == 0) {
            addNewSections = false;
        }
        
        let files = this.inputFile.files;
        if (!files || !files.length) {
            return;
        }

        let file = files[0];
        let fileReader = new FileReader();
        fileReader.onload = (event) => {
            let course = this.loadCourseFromFileReaderEvent(event, file);
            if (!course) {
                toastr.error(Resources.text.failed_to_load_file);
                return;
            }
            TaskManager.doTask(new ImportTask(course, addNewSections));
        };
        
        fileReader.readAsText(file);
    }

    private loadCourseFromFileReaderEvent(event: ProgressEvent<FileReader>, file: File): Course | null {
        let text = event.target.result;
        var courseXml = Utility.parseCourseXml(text as string);
        if (!courseXml) {
            // 파일 인식 불가
            toastr.error(Resources.text.failed_to_load_file);
            return;
        }

        let course: Course;
        switch (courseXml.type) {
            case 'tcx':
                course = this.createCourseFromTcx(courseXml.document, file.name);
                break;
            case 'gpx':
                course = this.createCourseFromGpx(courseXml.document, file.name);
                break;
        }

        return course;
    }

    private createCourseFromTcx(document: Document, filename: string): Course {
        let courseElement = document.getElementsByTagName('Course')[0];
        let courseName: string = null;
        if (courseElement) {
            for (let i = 0; i < courseElement.children.length; i++) {
                let c = courseElement.children[i];
                if (c.tagName == 'Name') {
                    courseName = c.textContent;
                }
            }
        }

        if (!courseName) {
            courseName = filename;
        }

        let elements = document.getElementsByTagName('Trackpoint');
        let trackpointNodes = [];
        for (let i = 0; i < elements.length; i++) {
            trackpointNodes.push(elements[i]);
        }

        elements = document.getElementsByTagName('CoursePoint');
        let waypointNodes = [];
        for (let i = 0; i < elements.length; i++) {
            waypointNodes.push(elements[i]);
        }

        let points: Point[] = [];
        trackpointNodes.forEach((tackpointNode) => {
            let point = this.createPointFromTrackpointNode(tackpointNode);
            if (point) {
                points.push(point);
            }
        });

        let waypoints: Waypoint[] = [];
        waypointNodes.forEach((waypointNode) => {
            var waypoint = this.createWaypointFromWaypointNode(waypointNode);
            waypoints.push(waypoint);
        });

        return this.createCourse(points, waypoints, courseName);
    }

    private createCourseFromGpx(document: Document, filename: string): Course {
        let metadatas = document.getElementsByTagName('metadata');
        var metadata = metadatas && metadatas.length ? metadatas[0] : null;
        let courseName: string = null;
        if (metadata) {
            for (let i = 0; i < metadata.children.length; i++) {
                let c = metadata.children[i];
                if (c.tagName == 'name') {
                    courseName = c.textContent;
                }
            }
        }
        if (!courseName) {
            courseName = filename;
        }

        let trkpts = document.getElementsByTagName('trkpt');
        let wpts = document.getElementsByTagName('wpt');

        let points: Point[] = [];
        for (let i = 0; i < trkpts.length; i++) {
            let trkptNode = trkpts.item(i);
            let point = this.createPointFromTrkptNode(trkptNode);
            points.push(point);
        }

        let waypoints: Waypoint[] = [];
        for (let i = 0; i < wpts.length; i++) {
            let wptNode = wpts.item(i);
            let waypoint = this.createWaypointFromWptNode(wptNode);
            waypoints.push(waypoint);
        }

        return this.createCourse(points, waypoints, courseName);
    }

    private createCourse(points: Point[], waypoints: Waypoint[], courseName: string): Course {
        let course = new Course();
        course.name = courseName;

        points.forEach((point) => {
            let sectionIndex = point.sectionIndex || 0;
            while (sectionIndex >= course.sections.length) {
                course.sections.push(Section.create());
            }
            course.sections[sectionIndex].points.push(point);
        });

        // 빈 section은 제거
        for (let i = course.sections.length - 1; i >= 0; i--) {
            let section = course.sections[i];
            if (!section.points.length) {
                course.sections.splice(i, 1);
            }
        }

        course.updateTotalValues();

        let allPoints = course.allPoints();

        // waypoint를 allPoints 상의 한점에 할당
        waypoints.forEach((waypoint) => {
            // 1. pointIndex를 이용해 탐색
            if (!isNothing(waypoint.pointIndex)) {
                for (let point of allPoints) {
                    if (point.pointIndex === waypoint.pointIndex) {
                        point.waypoint = waypoint;
                        return;
                    }
                }
            }

            // 2. pointId를 이용해 탐색
            if (!isNothing(waypoint.pointId)) {
                for (let point of allPoints) {
                    if (point.id === waypoint.pointId) {
                        point.waypoint = waypoint;
                        return;
                    }
                }
            }

            // 3. date를 이용한 탐색
            if (!isNothing(waypoint.date)) {
                for (let i = 1; i < allPoints.length; i++) {
                    let pPoint = allPoints[i - 1];
                    let point = allPoints[i];

                    let distanceFromPPoint = Utility.distanceMeterBetween(waypoint.latitude, waypoint.longitude, pPoint.latitude, pPoint.longitude);

                    let distanceFromPoint = Utility.distanceMeterBetween(waypoint.latitude, waypoint.longitude, point.latitude, point.longitude);

                    let distanceFromLine = Utility.pointLineDistance(waypoint.longitude, waypoint.latitude, pPoint.longitude, pPoint.latitude, point.longitude, point.latitude);

                    if (!pPoint.waypoint && pPoint.date == waypoint.date && distanceFromPPoint < 5) {
                        pPoint.waypoint = waypoint;
                        return;
                    } else if (pPoint.date < waypoint.date && waypoint.date < point.date && distanceFromLine < 0.5) {
                        let newPoint = new Point(waypoint.latitude, waypoint.longitude);
                        newPoint.waypoint = waypoint;
                        course.insertPointBefore(newPoint, point);
                        allPoints = course.allPoints();
                        return;
                    } else if (!point.waypoint && point.date == waypoint.date && distanceFromPoint < 5) {
                        point.waypoint = waypoint;
                        return;
                    }
                }
            }

            // 4. 가장 가까운 점에 설정
            let nearestLinePoints: Point[];
            let nearestLineDistance: number;

            for (let i = 1; i < allPoints.length; i++) {
                let pPoint = allPoints[i - 1];
                let point = allPoints[i];
                let distanceFromLine = Utility.pointLineDistance(waypoint.longitude, waypoint.latitude, pPoint.longitude, pPoint.latitude, point.longitude, point.latitude);

                if ((distanceFromLine < 0.5 && nearestLineDistance == undefined) || nearestLineDistance > distanceFromLine) {
                    nearestLinePoints = [pPoint, point];
                    nearestLineDistance = distanceFromLine;
                }
            }

            if (nearestLinePoints) {
                let newPoint = new Point(waypoint.latitude, waypoint.longitude);
                newPoint.waypoint = waypoint;
                course.insertPointBefore(newPoint, nearestLinePoints[1]);
                allPoints = course.allPoints();
                return;
            }
        });

        return course;
    }

    private createPointFromTrackpointNode(trackpointNode: Element): Point {
        let pointIndex = parseInt(trackpointNode.getAttribute('pointIndex'));
        let sectionIndex = parseInt(trackpointNode.getAttribute('sectionIndex'));
        let originalElevation = parseFloat(trackpointNode.getAttribute('originalElevation'));
        let isOriginalElevationFromOSM = trackpointNode.getAttribute('isOriginalElevationFromOSM') == 'true';

        let pointObject: any = {
            sectionIndex: sectionIndex,
            pointIndex: pointIndex,
            originalElevation: originalElevation,
            isOriginalElevationFromOSM: isOriginalElevationFromOSM,
        };

        // 구버젼 대응
        if (isNaN(pointIndex)) {
            pointObject.id = parseInt(trackpointNode.getAttribute('pointId'));
        }

        // 구버젼 대응
        if (isNaN(sectionIndex)) {
            pointObject.sectionId = parseInt(trackpointNode.getAttribute('routeId'));
        }

        // 구버젼 대응
        if (isNaN(originalElevation)) {
            pointObject.originalElevation = parseFloat(trackpointNode.getAttribute('elevationOriginal'));
        }

        trackpointNode.childNodes.forEach((childNode) => {
            let element = childNode as Element;
            let textContent = element.textContent;
            let tagName = element.tagName;

            switch (tagName) {
                case 'Position':
                    pointObject.latitude = parseFloat(element.getElementsByTagName('LatitudeDegrees')[0].textContent);
                    pointObject.longitude = parseFloat(element.getElementsByTagName('LongitudeDegrees')[0].textContent);
                    break;
                case 'AltitudeMeters':
                    pointObject.elevation = parseFloat(textContent) || 0;
                    break;
                case 'Time':
                    pointObject.date = textContent;
                    break;
            }
        });

        if (!pointObject.latitude || !pointObject.longitude) {
            return null;
        }

        return Point.fromJson(pointObject);
    }

    private createWaypointFromWaypointNode(waypointNode: Element): Waypoint {
        let pointIndex = parseInt(waypointNode.getAttribute('pointIndex'));
        let sectionIndex = parseInt(waypointNode.getAttribute('sectionIndex'));

        let waypointObject: any = {
            sectionIndex: sectionIndex,
            pointIndex: pointIndex,
        };

        // 구버젼 대응
        if (isNaN(pointIndex)) {
            waypointObject.pointId = parseInt(waypointNode.getAttribute('pointId'));
        }

        // 구버젼 대응
        if (isNaN(sectionIndex)) {
            waypointObject.sectionId = parseInt(waypointNode.getAttribute('routeId'));
        }

        waypointNode.childNodes.forEach((childNode) => {
            let element = childNode as Element;
            let tagName = element.tagName;
            let textContent = element.textContent;

            switch (tagName) {
                case 'Position':
                    waypointObject.latitude = parseFloat(element.getElementsByTagName('LatitudeDegrees')[0].textContent);
                    waypointObject.longitude = parseFloat(element.getElementsByTagName('LongitudeDegrees')[0].textContent);
                    break;
                case 'Name':
                    waypointObject.name = textContent;
                    break;
                case 'PointType':
                    waypointObject.type = textContent;
                    break;
                case 'Notes':
                    waypointObject.note = textContent;
                    break;
                case 'Time':
                    waypointObject.date = textContent;
                    break;
            }
        });

        return Waypoint.fromJson(waypointObject);
    }

    private createPointFromTrkptNode(trkptNode: Element): Point {
        let pointObject: any = {};
        pointObject.latitude = parseFloat(trkptNode.getAttribute('lat'));
        pointObject.longitude = parseFloat(trkptNode.getAttribute('lon'));

        pointObject.sectionIndex = parseInt(trkptNode.getAttribute('sectionIndex'));
        if (isNaN(pointObject.sectionIndex)) {
            // 구버젼 대응
            pointObject.sectionId = parseInt(trkptNode.getAttribute('routeId'));
        }

        pointObject.pointIndex = parseInt(trkptNode.getAttribute('pointIndex'));
        if (isNaN(pointObject.pointIndex)) {
            // 구버젼 대응
            pointObject.id = parseInt(trkptNode.getAttribute('pointId'));
        }

        pointObject.originalElevation = parseFloat(trkptNode.getAttribute('originalElevation'));
        if (isNaN(pointObject.originalElevation)) {
            // 구버젼 대응
            pointObject.originalElevation = parseFloat(trkptNode.getAttribute('elevationOriginal'));
        }

        pointObject.isOriginalElevationFromOSM = trkptNode.getAttribute('isOriginalElevationFromOSM') == 'true';

        trkptNode.childNodes.forEach((childNode) => {
            let element = childNode as Element;
            var tagName = element.tagName;
            var textContent = element.textContent;
            if (tagName == 'ele') {
                pointObject.elevation = parseFloat(textContent);
            }
        });

        return Point.fromJson(pointObject);
    }

    private createWaypointFromWptNode(wptNode: Element): Waypoint {
        let waypointObject: any = {};
        waypointObject.latitude = parseFloat(wptNode.getAttribute('lat'));
        waypointObject.longitude = parseFloat(wptNode.getAttribute('lon'));
        waypointObject.type = wptNode.getAttribute('type');

        waypointObject.sectionIndex = parseInt(wptNode.getAttribute('sectionIndex'));
        if (isNaN(waypointObject.sectionIndex)) {
            // 구버젼 대응
            waypointObject.sectionId = parseInt(wptNode.getAttribute('routeId'));
        }

        waypointObject.pointIndex = parseInt(wptNode.getAttribute('pointIndex'));
        if (isNaN(waypointObject.pointIndex)) {
            // 구버젼 대응
            waypointObject.pointId = parseInt(wptNode.getAttribute('pointId'));
        }

        wptNode.childNodes.forEach((childNode) => {
            let element = childNode as Element;
            let tagName = element.tagName;
            let textContent = element.textContent;
            if (tagName == 'name') {
                waypointObject.name = textContent;
            }
        });

        return Waypoint.fromJson(waypointObject);
    }
}

class ImportTask implements IgnoreSectionEditorLockTask {
    private importedCourse: Course;
    private selectedSectionIndexToRestore: number;
    private addNewSections: boolean;

    private courseToRestore: Course;

    constructor(importedCourse: Course, addNewSections: boolean) {
        this.importedCourse = importedCourse;
        this.addNewSections = addNewSections;
        this.selectedSectionIndexToRestore = ApplicationState.course.sections.indexOf(ApplicationState.selectedSection);
    }

    get ignoreSectionEditorLock(): boolean {
        return !this.addNewSections;
    }

    do(): void {
        if (this.addNewSections) {
            this.importedCourse.sections.forEach((section) => {
                section.name = ApplicationState.newSectionName(ApplicationState.course);
                ApplicationState.course.sections.push(section);
                ApplicationState.executeListeners(ApplicationEvent.SECTION_ADDED, section);
            });

            ApplicationState.selectedSection = ApplicationState.course.lastSection;
            MapController.setVisibleAllPoints(ApplicationState.selectedSection.points);
        } else {
            this.courseToRestore = ApplicationState.course;
            ApplicationState.course = this.importedCourse;
            ApplicationState.replaceWindowHistoryStateToEditingCourse();
        }
    }

    undo(): void {
        if (this.addNewSections) {
            this.importedCourse.sections.forEach((section) => {
                let index = ApplicationState.course.sections.indexOf(section);
                if (index >= 0) {
                    ApplicationState.course.sections.splice(index, 1);
                    ApplicationState.executeListeners(ApplicationEvent.SECTION_REMOVED, section);
                }
            });

            ApplicationState.selectedSection = ApplicationState.course.sections[this.selectedSectionIndexToRestore];
            MapController.setVisibleAllPoints(ApplicationState.selectedSection.points);
        } else {
            ApplicationState.course = this.courseToRestore;
        }
    }
}
