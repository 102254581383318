import { CourseRange, Point, Section, MapSection } from "../common/Ridingazua.Model";
import { HTMLUtility } from "./Ridingazua.HTMLUtility";
import { ApplicationState, ApplicationEvent, ApplicationEventListener } from "./Ridingazua.ApplicationState";
import { MapController, MapUtility } from "./Ridingazua.MapController";
import { Resources } from "./Ridingazua.Resources";
import { isNothing } from "../common/Ridingazua.Utility";
import { LatLng, LatLngBounds, MapMarker, MapPolyline, MapRectangle } from "./Ridingazua.MapWrapper";
import { MapConstants } from "./Ridingazua.MapConstants";

export class SelectedRangeController implements ApplicationEventListener {
    private static instance: SelectedRangeController;
    private div: HTMLDivElement
    private pDescription: HTMLParagraphElement;
    private selectedRange?: CourseRange;
    private selectedRangeBounds?: LatLngBounds;

    static set selectedRange(value: CourseRange | null) {
        if (this.selectedRange == value) {
            return;
        }

        this.instance.selectedRange = value;

        if (value) {
            let points = ApplicationState.course.getPointsBetween(
                value.startPoint.distanceFromCourseStart,
                value.finishPoint.distanceFromCourseStart
            );
            this.instance.selectedRangeBounds = MapUtility.getBoundsFromPoints(points, 0.02);
        } else {
            this.instance.selectedRangeBounds = null;
        }

        ApplicationState.executeListeners(
            value ? ApplicationEvent.SELECT_RANGE : ApplicationEvent.DESELECT_RANGE,
            value
        )
    }

    static get selectedRange(): CourseRange | null {
        return this.instance?.selectedRange;
    }

    private constructor() {
        this.createDivSelectedRange();
        ApplicationState.addListener(this);
    }

    handleApplicationEvent(event: ApplicationEvent, arg: any): void {
        switch (event) {
            case ApplicationEvent.SELECT_RANGE:
            case ApplicationEvent.DESELECT_RANGE:
                this.updateDivSelectedRange();
                this.updateMapObjects();
                break;
        }
    }

    static createInstance(): SelectedRangeController {
        if (!this.instance) {
            this.instance = new SelectedRangeController();
        }

        return this.instance;
    }

    private createDivSelectedRange(): HTMLDivElement {
        let div = document.createElement('div');
        div.classList.add('selected-range-info-layer');
        this.div = div;

        let divRemoveButton = document.createElement('div');
        div.appendChild(divRemoveButton);
        divRemoveButton.style.textAlign = 'right';

        let removeButton = HTMLUtility.createIconButton('Remove', 'close', () => {
            this.selectedRange = null;
            ApplicationState.executeListeners(ApplicationEvent.DESELECT_RANGE);
        });
        divRemoveButton.appendChild(removeButton);
        removeButton.classList.add('tiny');

        let p = document.createElement('p');
        div.appendChild(p);
        p.style.marginTop = '5px';
        this.pDescription = p;

        return div;
    }

    private updateDivSelectedRange() {
        this.div.remove();

        if (!this.selectedRange) {
            return;
        }

        let startPoint = this.selectedRange.startPoint;
        let finishPoint = this.selectedRange.finishPoint;
        let startPosition = `${(startPoint.distanceFromCourseStart / 1000).toFixed(1)}km`;
        let finishPosition = `${(finishPoint.distanceFromCourseStart / 1000).toFixed(1)}km`;
        let lengthKm = `${(this.selectedRange.lengthMeter / 1000).toFixed(1)}km`;
        let gainMeter = `${(finishPoint.elevation - startPoint.elevation).toFixed(1)}m`;
        let avgSlopePercent = `${(this.selectedRange.averageSlope * 100).toFixed(1)}%`;
        let components = [
            `${Resources.text.position_start}: ${startPosition}`,
            `${Resources.text.position_finish}: ${finishPosition}`,
            `${Resources.text.length}: ${lengthKm}`,
            `${Resources.text.elevation_gain}: ${gainMeter}`,
            `${Resources.text.average_slope}: ${avgSlopePercent}`,
        ];

        let climbWaypointType = this.selectedRange.climbCategoryWaypointType();
        if (!isNothing(climbWaypointType)) {
            components.push(
                `${Resources.text.climb_category}: ${climbWaypointType.name}`
            );
        }

        this.pDescription.innerHTML = components.join('<br />');
    }

    private linesForSelectedRange: MapPolyline[] = [];
    private markersForRange: MapMarker[] = [];
    private rectForRange?: MapRectangle;

    private updateMapObjects() {
        this.linesForSelectedRange.forEach(line => {
            line.map = null;
        });

        this.markersForRange.forEach(marker => {
            marker.map = null;
        });

        if (this.rectForRange) {
            this.rectForRange.map = null;
        }

        this.linesForSelectedRange = [];
        this.markersForRange = [];
        this.rectForRange = null;

        let range = this.selectedRange;

        if (!range) {
            return;
        }

        let map = ApplicationState.map;
        let course = ApplicationState.course;

        let pathInfos: any[] = [];
        let pathPoints = course.getPointsBetween(
            range.startPoint.distanceFromCourseStart,
            range.finishPoint.distanceFromCourseStart
        );

        class MapPathInfo {
            path?: LatLng[];
            points?: Point[];
            section?: Section;
        }

        let pathInfo: MapPathInfo | null;

        for (let point of pathPoints) {
            if (pathInfo) {
                let lastPoint = pathInfo.points[pathInfo.points.length - 1];
                if (lastPoint.sectionIndex !== point.sectionIndex) {
                    pathInfo = null;
                }
            } else {
                pathInfo = new MapPathInfo();

                if (!isNothing(point.sectionIndex)) {
                    pathInfo.section = course.sections[point.sectionIndex];
                }

                pathInfo.path = new Array<LatLng>();
                pathInfo.points = new Array<Point>();
                pathInfos.push(pathInfo);
            }

            pathInfo.path.push(LatLng.fromPoint(point));
            pathInfo.points.push(point);
        };

        pathInfos.forEach(pathInfo => {
            const path = pathInfo.path as LatLng[];
            const section = pathInfo.section as Section;
            const polylines = map.createSelectedRangePolylines(path, section);
            polylines.forEach(polyline => {
                this.linesForSelectedRange.push(polyline);
            });
        });

        this.markersForRange.push(
            map.createStartOrEndPointMarker(
                true,
                LatLng.fromPoint(pathPoints[0])
            )
        );

        this.markersForRange.push(
            map.createStartOrEndPointMarker(
                false,
                LatLng.fromPoint(pathPoints[pathPoints.length - 1])
            )
        );

        if (this.selectedRangeBounds) {
            this.rectForRange = map.createSelectedBoundsRectangle(this.selectedRangeBounds);
        }

        MapController.setVisibleAllPoints(pathPoints);
    }

    static updateSelectedRangeInfoLayerPosition() {
        if (!this.selectedRange) {
            return;
        }

        let bounds = this.instance.selectedRangeBounds;
        if (!bounds) {
            return;
        }

        let pxPosition = ApplicationState.map.convertMapPositionToPixelPosition(
            new LatLng(bounds.north, bounds.west)
        )

        HTMLUtility.showElementInContainer(
            this.instance.div,
            ApplicationState.map.div,
            pxPosition[0],
            pxPosition[1],
            MapConstants.zIndexForSelectedRangeInfoLayer,
            5,
            'right'
        );
    }
}